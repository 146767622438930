import React from 'react';
import {Marker} from 'react-leaflet';
import {Icon, LatLng, LeafletMouseEvent} from 'leaflet';
import markerIconSvg from './assets/circle-solid.svg';
import {PolygonPoint} from '../../../store/map';

export interface PointProps {
    /** a polygon point model to be represented as a marker on a map */
    point: PolygonPoint;
    onPointDrag: (id: string, position: LatLng) => void;
    /** used not to interfere with a drag process */
    onPointDragEnd: (id: string) => void;
    onClick: (id: string, e: LeafletMouseEvent) => void;
    onDblClick: (id: string, e: LeafletMouseEvent) => void;
}

const iconRealMarker = new Icon({
    iconUrl: markerIconSvg,
    iconSize: [15, 15],
});

const iconEditMarker = new Icon({
    iconUrl: markerIconSvg,
    iconSize: [8, 8],
});

/**
 * Create a polygon point on a map
 * @param {PointsProps} props
 */
export function Point({point, onPointDrag, onPointDragEnd, onDblClick, onClick}: PointProps) {
    const handlePointMove = (e: LeafletMouseEvent) => {
        onPointDrag(point.id, e.latlng);
    };
    const handleDragEnd = () => {
        onPointDragEnd(point.id);
    };
    const handleClick = (e: LeafletMouseEvent) => {
        onClick(point.id, e);
    };
    const handleDblClick = (e: LeafletMouseEvent) => {
        onDblClick(point.id, e);
    };

    return (
        <Marker
            className="point__marker"
            icon={point.type === 'real' ? iconRealMarker : iconEditMarker}
            position={point.position}
            draggable={true}
            onDrag={handlePointMove}
            onDragEnd={handleDragEnd}
            onClick={handleClick}
            onDblClick={handleDblClick}
            title={`id=${point.id}, type=${point.type}`}></Marker>
    );
}
