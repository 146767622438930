import {CrossSectionActionTypes, LOAD_WELLS_DATA_FAIL, LOAD_WELLS_DATA_START, LOAD_WELLS_DATA_SUCCESS,} from './types';
import {CrossSectionResponse, fetchWellMarkers} from '../../api/cross-section.api';
import {AppState} from '../index';
import {ThunkAction} from 'redux-thunk';

type AppThunk<ReturnType = void> = ThunkAction<ReturnType, AppState, null, CrossSectionActionTypes>;

export function loadWellsDataStartAction(wellIds: string[]): CrossSectionActionTypes {
    return {
        type: LOAD_WELLS_DATA_START,
        payload: wellIds,
    };
}

export function loadWellsDataSuccessAction(
    response: CrossSectionResponse
): CrossSectionActionTypes {
    return {
        type: LOAD_WELLS_DATA_SUCCESS,
        payload: response,
    };
}

export function loadWellsDataFailAction(err: Error): CrossSectionActionTypes {
    return {
        type: LOAD_WELLS_DATA_FAIL,
        payload: err,
    };
}

/**
 * Fetch a markers data from the backend to construct a cross section report
 * @param wellIds well ids to fetch markers data for
 */
export const loadWellsDataAction = (wellIds: string[]): AppThunk => dispatch => {
    dispatch(loadWellsDataStartAction(wellIds));

    return fetchWellMarkers(wellIds)
        .then(data => dispatch(loadWellsDataSuccessAction(data)))
        .catch(err => dispatch(loadWellsDataFailAction(err)));
};
