import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles(theme=>(
  {
    select: {
        marginBottom: theme.spacing(4),
        width: '100%',
        '&:last-of-type': {
          marginBottom: theme.spacing(2),
        }
      },
  }
))
const StyledAutoComplete = (props) => {
    const classes = useStyles();
return (
<Autocomplete        
        id={props.id}
        className={classes.select}
        options={props.options}
        getOptionLabel={(option) => option.name}
        defaultValue={props.defaultValue}
        closeIcon={null}
        fullWidth
        renderInput={(params) =>
        <TextField {...params} label={props.label}
            InputLabelProps={{ className: classes.TextFieldLabel }}
           variant="filled" className={classes.textField} />}
    />
)
}

export default StyledAutoComplete;
StyledAutoComplete.propTypes = { 
  options: PropTypes.array,
  defaultValue: PropTypes.string,
  label: PropTypes.string
}