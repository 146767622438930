import React from 'react';
import {useSelector} from 'react-redux';
import {AppState} from '../../store';
import {MainPage} from './MainPage';

/**
 * Contains login-logout functionality, search wells form,
 * found wells list and area for drawing weel trajectories
 */
export function ConnectedMainPage() {
    const trajectories = useSelector((state: AppState) => state.trajectory.trajectories);
    const isTrajectoryLoading = trajectories.some(t => t.isLoading);
    const readyToDraw = trajectories.filter(t => t.isLoaded && t.loadError === undefined);

    return <MainPage isTrajectoryLoading={isTrajectoryLoading} trajectoriesToDraw={readyToDraw}/>;
}
