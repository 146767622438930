import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),   
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  select: {
    color: "white"
  }
}));

const SelectWithStyles  = (props) => {
  const classes = useStyles();

  return (
    <FormControl className={classes.formControl} margin="dense">
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={props.currentValue}
        onChange={props.handleSelectValue}
        className={classes.select}
        disableUnderline
      >
        <MenuItem value={props.selection.title}>
            {props.selection.title}
        </MenuItem>
        {props.selection.options.map(option => {
          return <MenuItem value={option.value} key={option.value}>{option.name}</MenuItem>;
        })}

      </Select>
    </FormControl>
  );
}

export default SelectWithStyles;