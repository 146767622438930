import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import {AppBar, Grid, Hidden, IconButton, Toolbar} from '@material-ui/core';
import {AppBarBackGround, LogoWhite} from '../../assets';

const useStyles = makeStyles(theme => ({
    root: {
        boxShadow: 'none',
        backgroundColor: theme.palette.primary.dark,
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        borderBottom: `${theme.height.topBarBorder}px solid ${theme.palette.primary.main}`
    },
    toolBar: {
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(0),
        height: theme.height.topBar
    },
    container: {
        height: '100%',
        flexWrap: 'nowrap'
    },
    menuContainer: {
        flexWrap: 'nowrap'
    },
    logo: {
        width: 437,
        height: '60px',
        textAlign: 'center',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(1)
    },
    menuRight: {
        justifyContent: 'flex-end'
    },
    flexGrow: {
        flexGrow: 1,
        [theme.breakpoints.down('md')]: {
            flexGrow: 0,
            display: 'flex',
            alignContent: 'center'
        }
    },
    spaceBetween: {
        justifyContent: 'space-between'
    },
    backgroundImage: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%'
    },
    alignCenter: {
        alignSelf: 'center'
    },
    userMenuContainer: {
        marginRight: -theme.spacing(3) // offset the padding on the button icon
    },
    workloadMenuContainer: {
        paddingTop: theme.spacing(1)
    }

}));

const TopBar = (props) => {
    const classes = useStyles();
    let backgroundImageWidth = window.width;
    return (
        <AppBar className={clsx(classes.root, props.className)} id='appBar'>
            <AppBarBackGround className={classes.backgroundImage} width={backgroundImageWidth}/>
            <Toolbar className={classes.toolBar}>
                <Grid container className={clsx(classes.spaceBetween, classes.container)}>
                    <Hidden lgUp={true}>
                        <Grid item className={classes.alignCenter}>
                            <IconButton aria-controls="simple-menu"
                                        aria-haspopup="true"
                                        onClick={props.toggleSideBar}
                                        color='inherit'>
                                {props.menuIcon}
                            </IconButton>
                        </Grid>
                    </Hidden>
                    <Grid item>
                        <RouterLink to="/">
                            <div className={classes.logo}>
                                <LogoWhite/>
                            </div>
                        </RouterLink>
                    </Grid>
                    <Grid container item className={classes.menuContainer}>
                        <Hidden mdDown>
                            <Grid item className={classes.alignCenter}>
                                {props.serviceMenu}
                            </Grid>
                        </Hidden>
                        <Grid item container className={classes.menuRight}>
                            <Hidden mdDown>
                                <Grid item className={classes.workloadMenuContainer}>
                                    {props.workloadMenu}
                                </Grid>
                                <Grid item className={clsx(classes.alignCenter, classes.userMenuContainer)}>
                                    {props.userMenu}
                                </Grid>
                                <Grid item className={classes.alignCenter}>
                                    {props.helpMenu}
                                </Grid>
                            </Hidden>
                        </Grid>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    );
};
export default TopBar;
TopBar.propTypes = {
    className: PropTypes.string,
    onSidebarOpen: PropTypes.func,
    serviceMenu: PropTypes.node,
    workloadMenu: PropTypes.node,
    userMenu: PropTypes.node,
    menuIcon: PropTypes.node,
    toggleSideBar: PropTypes.func,
    helpMenu: PropTypes.node
};


