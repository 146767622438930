import React from 'react';
import Typography from "@material-ui/core/Typography";
import {Card, CardContent} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles(theme => (
    {
        table: {
            textAlign: 'left',
            marginLeft: 'auto',
            marginRight: 'auto',
            borderCollapse: 'separate',
            borderSpacing: '50px 10px'
        }
    }
));
export default function OSDUTestDataSet() {
    const classes = useStyles();

    return (
        <div>
            <Card>
                <CardContent>
                    <Typography variant="h4">
                        OSDU™  Test Data Sets
                    </Typography>
                    <br/>
                    <Typography component="div">
                        Included for use in this environment is the OSDU Forum provided schemas and test data sets. The test
                        dataset in the OSDU Data Platform includes Wells and Seismic domains.
                    </Typography>
                    <br/>
                    <br/>

                    <Typography variant="h5">
                        OSDU Data Types
                    </Typography>
                    <br/>
                    <Typography component="div">
                        The OSDU standard provides for several types of data and usage patterns.
                        <ul>
                            <li><b>Reference Data</b> - Simple list of values</li>
                            <li><b>Master Data</b> - Reference data with additional details and embedded Reference Data
                            </li>
                            <li><b>Work Product Components</b> - Complex data types that make of the bulk of the
                                datasets. They
                                have Master and Reference data links within their instances.
                            </li>
                        </ul>
                    </Typography>
                    <br/>
                    <br/>

                    <Typography variant="h5">
                        OSDU Schemas
                    </Typography>
                    <br/>
                    <Typography component="div">
                        The OSDU standard uses JSON schemas to define the shape and format of the data ingested and stored in the
                        system. When a new type or kind is defined, the system uses the JSON schema to cement that
                        definition. Subsequent data is supplied to the system in the form of instances that conform to
                        the
                        schema of that kind.
                    </Typography>
                    <br/>
                    <br/>

                    <Typography variant="h5">
                        OSDU Domains
                    </Typography>
                    <br/>
                    <Typography component="div">
                        This is the list of available test data sets included as part of 47Lining Enterprise PaaS. Check the <a
                        className='text-link' rel="noopener noreferrer" target="_blank"
                        href="https://community.opengroup.org/osdu/program/-/wikis/Release_Planning/R3/Test_Data#data-sets"> OSDU
                        GIT </a>
                        for updated list and added information. Also, check the OSDU
                        <a className='text-link' rel="noopener noreferrer" target="_blank"
                           href="https://files.opengroup.org/osdu"> Test Data Site </a>

                        for OSDU Data Platform specific procedures, scripts and artifacts needed attached to the original
						test data sets for use within the OSDU Data Platform.
                        <br/>
                        <br/>
                        <table className={classes.table}>
                            <thead>
                            <tr>
                                <th>Name</th>
                                <th>Source</th>
                                <th>Additional info</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>TNO</td>
                                <td>Well based data package from TNO Geological Survey of the Netherlands.
                                    <a className='text-link' rel="noopener noreferrer" target="_blank"
                                       href="https://nlog.nl/"> https://nlog.nl/ </a>
                                </td>
                                <td>Used in OSDU Data Platform releases R1 and R2 as well as demo solutions. Free for open use.</td>
                            </tr>
                            <tr>
                                <td>UK Greater Buchan Area</td>
                                <td>UK CDA/NDR disclosed public information. A useful FAQ can be found
                                    <a className='text-link' rel="noopener noreferrer" target="_blank"
                                       href="https://ndr.ogauthority.co.uk/dp/pages/customcode/General/jsp/FAQs.jsp"> here.</a>
                                </td>
                                <td>Many data sets are available here. Not as rich and geographically contained as
                                    Volve
                                    but contains larger data sets in both the seismic and well domain.
                                </td>
                            </tr>
                            <tr>
                                <td>Volve</td>
                                <td>Equinor and Volve license partners.</td>
                                <td>Small abandoned field in the North Sea. Very rich data set related to content.
                                    Available for OSDU Data Platform Development, Testing and Certification.
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </Typography>
                </CardContent>
            </Card>
        </div>
    )
}