import React from 'react';
import PropTypes from 'prop-types';
import {Box, CircularProgress} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        flex: 1
    }
}))

function ProgressWithLabel(props) {
    const classes = useStyles();
    return (
        <Box className={classes.container}>
            <Box position="relative" display="inline-flex">
                <CircularProgress variant="indeterminate"
                                  size={200}
                                  thickness={1.5}
                                  {...props} />
                <Box
                    top={0}
                    left={0}
                    bottom={0}
                    right={0}
                    position="absolute"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                </Box>
            </Box>
        </Box>

    );
}

export default ProgressWithLabel;

ProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator
     * for the determinate and static variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
};

