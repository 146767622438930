const white = '#FFFFFF';
const black = '#6A717A';

export default {
    black,
    white,   
    grey1: '#FAFBFC',
    grey2:'#E0E4E9',   
    grey3: '#B2BBC1',
    grey4: '#6A717A' ,    
    grey5: '#30373E',  
    primary: {
        contrastText: white,
        dark: '#043D6D',
        main: '#0165A6',
        light: '#3383B7'
    },
    secondary: {
        contrastText: white,
        dark: '#950000',
        main: '#AD0000',
        light: 'rgba(149, 0, 0, .9)',
    },
    success: {
        contrastText: white,
        main: '#388E3C'
    },
    info: {
        contrastText: white,
        main: '#6A717A'
    },
    warning: {
        contrastText: white,
        main: '#FBC02D'
    },
    error: {
        contrastText: white,
        main: '#AD0000',
    },
    text: {
        main: 'rgba(0, 0, 0, 0.87)',
        secondary: 'rgba(0, 0, 0, 0.6)',
        link: '#0165A6'       
    },
    background: {
        page: '#FAFBFC',       
        gradient: 'linear-gradient(90deg, #043D6D 0%, #0165A6 100%)',
        paper: white
    },      
    border: {
        primary: '#0165A6',
        secondary: '#AD0000',
        main: '#E0E4E9'
    }, 

    icon: '#FAFBFC',
    divider: '#B2BBC1',
    textField: '#E0E4E9',
    sideBar: '#141B2D',
    sideBarMenu:'#17212C'     
};
