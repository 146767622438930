import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AppState} from '../../../store';
import {
    addPointAction,
    changePointAction,
    clearSearchError,
    deletePointAction,
    finishDrawModeAction,
    makePointRealAction,
    setMapCenterAction,
    setMapZoomAction,
    toggleWellSelectionAction,
} from '../../../store/map/actions';
import {LatLng} from 'leaflet';
import {Map} from './Map';

export function ConnectedMap() {
    const dispatch = useDispatch();

    const currentUserPosition = useSelector((state: AppState) => state.map.currentUserPosition);
    const initialWell = useSelector((state: AppState) => state.map.initialWell);
    const mapZoom = useSelector((state: AppState) => state.map.mapZoom);
    const currentMapCenter = useSelector((state: AppState) => state.map.mapCenter);
    const isLoading = useSelector((state: AppState) => state.map.isLoading);
    const loadingError = useSelector((state: AppState) => state.map.loadingError);
    const points = useSelector((state: AppState) => state.map.points);
    const wells = useSelector((state: AppState) => state.map.wells);
    const isDrawingMode = useSelector((state: AppState) => state.map.isDrawingMode);
    const selectedWellIds = useSelector((state: AppState) => state.map.selectedWellIds);

    // smart map center evaluation required on an initial load
    // (51.48, 0) - is a Greenwich observatory location
    const mapCenter =
        currentMapCenter ||
        (initialWell ? initialWell.location : currentUserPosition) ||
        new LatLng(51.48, 0);

    // found wells and the initial one later will be handled in a similar way
    // also in case of collision, initial well is the only one to be drawn
    const wellsToDraw = initialWell
        ? [...wells.filter(w => w.resourceId !== initialWell.resourceId), initialWell]
        : [...wells];

    const handleZoom = (zoom: number) => {
        dispatch(setMapZoomAction(zoom));
    };
    const handleSetMapCenter = (center: LatLng) => {
        dispatch(setMapCenterAction(center));
    };
    const handleAddPoint = (position: LatLng) => {
        dispatch(addPointAction(position));
    };
    const handleChangePoint = (id: string, position: LatLng): void => {
        dispatch(changePointAction(id, position));
    };
    const handleMakePointReal = (id: string): void => {
        dispatch(makePointRealAction(id));
    };
    const handleFinishDrawMode = () => {
        dispatch(finishDrawModeAction());
    };
    const handleDeletePont = (id: string) => {
        dispatch(deletePointAction(id));
    };
    const hadleToggleWellSelection = (wellId: string) => {
        dispatch(toggleWellSelectionAction(wellId));
    };
    const handleErrorClose = () => {
        dispatch(clearSearchError());
    };

    return (
        <Map
            mapZoom={mapZoom}
            mapCenter={mapCenter}
            isDrawingMode={isDrawingMode}
            points={points}
            isLoading={isLoading}
            loadingError={loadingError}
            initialWell={initialWell}
            wellsToDraw={wellsToDraw}
            selectedWellIds={selectedWellIds}
            onZoom={handleZoom}
            onSetMapCenter={handleSetMapCenter}
            onAddPoint={handleAddPoint}
            onChangePoint={handleChangePoint}
            onMakePointReal={handleMakePointReal}
            onFinishDrawMode={handleFinishDrawMode}
            onDeletePoint={handleDeletePont}
            onToggleWellSelection={hadleToggleWellSelection}
            onErrorClose={handleErrorClose}
        />
    );
}
