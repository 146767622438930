import {WellMarkers} from '../../store/cross-section';

// These colors will be used to represent formations.
// Though they are limited by a magic quantity
// and are not earthy or sandy or... ommelandenny in their hue
export const chartColors: string[] = [
    '#1BBC9B',
    '#2DCC70',
    '#3598DB',
    '#9B58B5',
    '#34495E',
    '#149E8C',
    '#27AE61',
    '#2A80B9',
    '#8F44AD',
    '#2D3E50',
    '#F1C40F',
    '#E77E23',
    '#E84C3D',
    '#BEC3C7',
    '#7E8C8D',
    '#F39C11',
    '#D25400',
    '#C1392B',
    '#BEC3C7',
    '#F8F8F8',
];

export interface ChartDataItem {
    [key: string]: number | string;
}

// data looks like an object composed of a well name mixed with
// a dictionary of formations and their corresponding widths.
export function prepareChartData(wellMarkers: WellMarkers[]): ChartDataItem[] {
    return wellMarkers.reduce<ChartDataItem[]>((all: ChartDataItem[], well) => {
        const formationsData = well.formations.reduce<ChartDataItem>((data, formation, ind) => {
            // before this line of code depths were positive
            // but for a chart a higher depth value should be pointed down
            data[formation] = -well.markerDepths[ind];
            return data;
        }, {});

        // a touch of a fancy web-programmer magic!
        // To visualise subsurface in near vicinity of a well,
        // before rushing to formation's new values corresponding to another one,
        // I'm making two copies of each well (per copy for each side), which will resemble a plateau
        return all.concat([
            {...formationsData},
            {...formationsData, commonName: well.facilityName},
            {...formationsData},
        ]);
    }, []);
}
