import React from 'react';
import './styles.css';
import {Map as LeafletMapComponent, Polygon, Polyline, TileLayer} from 'react-leaflet';
import {LatLng, LeafletMouseEvent, Map as LeafletMap} from 'leaflet';
import {Hint, Loader} from '../../../components/shared';
import {WellPoint} from '../well-point';
import {Point} from '../point';
import {PolygonPoint, Well} from '../../../store/map';
import {TooltipPane} from '../../shared/tooltip-pane';

const searchFailedText = 'Something went wrong';

export interface MapProps {
    mapZoom: number;
    mapCenter?: LatLng;

    /** toggler between drawing and editing a polygon shape for a search use */
    isDrawingMode: boolean;
    /** points of a polygon */
    points: PolygonPoint[];

    /** flag to mark search in polygon api request progress */
    isLoading: boolean;
    /** a search in polygon error */
    loadingError?: Error;

    /** a well model, derived from url parameters */
    initialWell?: Well;
    /** wells, found after a search */
    wellsToDraw: Well[];
    /** well ids of wells, chosen by a user to create a cross-section report */
    selectedWellIds: string[];

    /** to keep map zoom synced with a store */
    onZoom: (z: number) => void;
    /** to keep map center synced with a store */
    onSetMapCenter: (c: LatLng) => void;

    /** add a new polygon point */
    onAddPoint: (p: LatLng) => void;
    /** move an existing polygon point */
    onChangePoint: (id: string, p: LatLng) => void;
    /** switch an 'edit' point to real one after a user's interaction */
    onMakePointReal: (id: string) => void;
    /** the decision is made in the component on a certain point click */
    onFinishDrawMode: () => void;
    /** remove a new polygon point */
    onDeletePoint: (id: string) => void;

    onToggleWellSelection: (wellId: string) => void;
    /** mark that a search in polygon error is acknowledged */
    onErrorClose: () => void;
}

/**
 * Creates a map using Leaflet javascript library
 * https://www.npmjs.com/package/react-leaflet
 * with an interface to create a polygon, display found wells and an ability to select them
 */
export function Map({
                        mapZoom,
                        mapCenter,

                        isDrawingMode,
                        points,

                        isLoading,
                        loadingError,

                        initialWell,
                        wellsToDraw,
                        selectedWellIds,

                        onZoom,
                        onSetMapCenter,
                        onAddPoint,
                        onChangePoint,
                        onMakePointReal,
                        onFinishDrawMode,
                        onDeletePoint,
                        onToggleWellSelection,
                        onErrorClose,
                    }: MapProps) {
    // save map zoom in the state to use it when the page is revisited
    const handleZoom = (e: LeafletMouseEvent) => {
        onZoom((e.target as LeafletMap).getZoom());
    };

    // save map position in the state to use it when the page is revisited
    const handleMove = (e: LeafletMouseEvent) => {
        onSetMapCenter((e.target as LeafletMap).getCenter());
    };

    // add a new point to the polygon
    const handleMapClick = (e: LeafletMouseEvent) => {
        if (isDrawingMode) {
            onAddPoint(e.latlng);
        }
    };

    // drag mechanic is provided by leaflet, we need to sync new position with our store
    const handlePointDrag = (id: string, position: LatLng): void => {
        onChangePoint(id, position);
    };

    // dragend - is the moment, every juvenile 'edit' point anticipates
    const handlePointDragEnd = (id: string): void => {
        onMakePointReal(id);
    };

    // tries to enclose a polygon
    const handlePointClick = (id: string, e: LeafletMouseEvent) => {
        if (!isDrawingMode) {
            return;
        }

        if (points.findIndex(p => p.id === id) === 0 && points.length >= 3) {
            // click into the first point is an intention to enclose the polygon
            onFinishDrawMode();
        }

        // action is acknowledged, disallow other possible actions
        // e.g. new point creation
        e.originalEvent.stopPropagation();
    };

    // deletes a polygon's point
    const handlePointDblClick = (id: string, e: LeafletMouseEvent) => {
        onDeletePoint(id);

        // action is acknowledged, disallow other possible actions
        // e.g. map zoom
        e.originalEvent.stopPropagation();
    };

    // used to determine wells composition for a cross-section report
    const hadleWellSelectionToggle = (wellId: string) => {
        onToggleWellSelection(wellId);
    };

    return (
        <div className="map">
            {/* Fade and popup for a 'search in polygon' error */}
            {loadingError && (
                <div className="map__fade">
                    <TooltipPane className="map__search-error">
                        <div className="map__close-error" onClick={onErrorClose}>
                            &times;
                        </div>
                        <Hint title={searchFailedText} subTitle={String(loadingError)}/>
                    </TooltipPane>
                </div>
            )}

            {/* Fade and Loader for a 'search in polygon' process */}
            {isLoading && (
                <div className="map__fade">
                    <TooltipPane className="map__loader">
                        <Loader size={30}/>
                    </TooltipPane>
                </div>
            )}

            <LeafletMapComponent
                center={mapCenter}
                zoom={mapZoom}
                onclick={handleMapClick}
                onzoomend={handleZoom}
                onmoveend={handleMove}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />

                {/* place user-defined point markers on the map */}
                {points.map(point => (
                    <Point
                        key={point.id}
                        point={point}
                        onPointDrag={handlePointDrag}
                        onPointDragEnd={handlePointDragEnd}
                        onClick={handlePointClick}
                        onDblClick={handlePointDblClick}
                    />
                ))}

                {/* Draw a polyline shape to connect current points */}
                {isDrawingMode ? (
                    points.map((point, i) => {
                        const nextPoint = points[i + 1];
                        return nextPoint ? (
                            <Polyline
                                key={`line_${i}_${i + 1}`}
                                positions={[point.position, nextPoint.position]}
                                color="grey"
                            />
                        ) : null;
                    })
                ) : (
                    <Polygon positions={points.map(p => p.position)} color="blue"/>
                )}

                {/* Draw well markers for cross-section compose */}
                {wellsToDraw.map(well => (
                    <WellPoint
                        key={well.resourceId}
                        well={well}
                        type={well === initialWell ? 'initial' : 'default'}
                        isSelected={selectedWellIds.includes(well.resourceId)}
                        onToggleSelected={hadleWellSelectionToggle}
                    />
                ))}
            </LeafletMapComponent>
        </div>
    );
}
