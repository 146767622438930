import {Card, CardContent} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React from "react";
import CustomerUseCasesImage from "./assets/customer_use_cases.png"
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles(theme => (
    {
        imageDiv: {
            marginTop: '20px',
            textAlign: 'center'
        },
        image: {
            width: '400px'
        },
    }
));

export default function CustomerUseCases() {

    const classes = useStyles();

    return (
        <div>
            <Card>
                <CardContent>
                    <Typography variant="h4">
                        Customer Use Cases
                    </Typography>
                    <br/>
                    <Typography variant="h5">
                        Exploration and Collaboration
                    </Typography>
                    <br/>
                    <Typography component="div">
                        As a first step in your journey to OSDU™ Data Platform adoption, you can use the 47Lining Enterprise
						PaaS environment to explore its suitability to support your upstream workflows. You can also work
						with 47Lining to collaborate on lead use cases to evaluate OSDU Data Platform capabilities to
						provide development workflows. Operators will want to evaluate the appropriate ways to integrate
						with Enterprise applications and corporate systems.
                        <br/>
                        <br/>
                        <div className={classes.imageDiv}>
                            <img className={classes.image} src={CustomerUseCasesImage} alt='x'/>
                        </div>
                    </Typography>
                    <br/>
                    <br/>

                    <br/>
                    <Typography variant="h5">
                        OSDU Organizational Fit
                    </Typography>
                    <br/>
                    <Typography component="div">
                        Many customers are curious about the OSDU Data Platform and the ways it can work in their
						organization. By evaluating their use cases and the fit with OSDU they can clarify how OSDU will
						fit into their plans for data platforms as it relates to their upstream needs. Customers may choose
						to go with new applications that are built exclusively on top of OSDU, others may want to retrofit
						their applications to also work with OSDU and still others will ask their partners to modify their
                        applications to be able to leverage the OSDU standard. Without a proper evaluation of the OSDU Data
						Platform and its capabilities with future roadmap, it is difficult to determine. 47Lining can help]
						organizations during this assessment to fully leverage OSDU and to provide as-a-service operating
						options to help lower cost and provide enhanced flexibility.
                    </Typography>
                    <br/>
                    <br/>
                </CardContent>
            </Card>
        </div>
    )
}