import { LatLng, GeoJSON } from 'leaflet';
import {
  SET_USER_POSITION,
  FIND_WELLS_SUCCESS,
  DELETE_POINT,
  CHANGE_POINT,
  ADD_POINT,
  FINISH_DRAW_MODE,
  MapActionTypes,
  TOGGLE_WELL_SELECTION,
  FIND_WELLS_START,
  FIND_WELLS_FAIL,
  SET_INITIAL_WELL,
  SET_MAP_ZOOM,
  SET_MAP_CENTER,
  CLEAR_ALL_POINTS,
  MAKE_POINT_REAL,
  CLEAR_SEARCH_ERROR,
} from './types';

export interface MapState {
  /** requested from a browser api and used to center the map */
  currentUserPosition?: LatLng;

  /** synchronized value to restore after navigation back to page */
  mapCenter?: LatLng;

  /** initial and later synchronized value to restore after navigation back to page */
  mapZoom: number;

  /** logical split between 'before valid polygon shape is drawn' and 'after' */
  isDrawingMode: boolean;

  /** user selected point to compose a polygon */
  points: PolygonPoint[];

  /** found wells after a successful fetch from api */
  wells: Well[];

  /** user-selected wells to compose a cross-section report */
  selectedWellIds: string[];

  /** wells fetch api request progress flag */
  isLoading: boolean;

  /** wells fetch from api error */
  loadingError?: Error;

  /** a well model, set to be pivotal */
  initialWell?: Well;
}

export interface PolygonPoint {
  /** an artificial id to help identify a point in various hadlers */
  id: string;

  /** geo position */
  position: LatLng;

  /** some points are interface helpers and may be descern by the flag */
  type: 'real' | 'edit';
}

export interface Well {
  resourceId: string;

  facilityName: string;

  /** surface location of a well */
  location: LatLng;
}

// a minimum number of points to form a valid polygon shape
export const MIN_POLYGON_LENGTH = 3;
// a minimum number of points to construct a reasonable cross-section report
export const MIN_WELLS_COUNT = 2;

const initialState: MapState = {
  currentUserPosition: undefined,
  mapCenter: undefined,
  mapZoom: 8,
  isDrawingMode: true,
  points: [],
  wells: [],
  selectedWellIds: [],
  isLoading: false,
  loadingError: undefined,
  initialWell: undefined,
};

export const mapReducer = (state = initialState, action: MapActionTypes): MapState => {
  switch (action.type) {
    case SET_INITIAL_WELL:
      if (
        (state.initialWell === undefined && action.payload === undefined) ||
        (state.initialWell !== undefined &&
          action.payload !== undefined &&
          state.initialWell.resourceId === action.payload.resourceId)
      ) {
        // An attempt to set the exact same initialWell (including undefined value)
        // means that user is navigating back/forward to the map page
        // so keep everything how it was left
        return state;
      }

      // full reset of a state, since we made a brand-new page opening
      return {
        ...state,
        initialWell: action.payload,
        // 15 is a comfortable value to look around the well. *based on a team's experience
        mapZoom: action.payload === undefined ? 8 : 15,
        mapCenter: undefined,
        loadingError: undefined,
        isDrawingMode: true,
        isLoading: false,
        points: [],
        selectedWellIds: [],
        wells: [],
      };
    case SET_USER_POSITION:
      return {
        ...state,
        currentUserPosition: action.payload,
      };
    case SET_MAP_ZOOM:
      return {
        ...state,
        mapZoom: action.payload,
      };
    case SET_MAP_CENTER:
      return {
        ...state,
        mapCenter: action.payload,
      };
    case FINISH_DRAW_MODE:
      return {
        ...state,
        isDrawingMode: false,
        points: updateEditPolygonPoints(state.points, false),
      };
    case ADD_POINT:
      return {
        ...state,
        points: updateEditPolygonPoints(
          [...state.points, createPolygonPoint(action.payload, 'real')],
          state.isDrawingMode
        ),
      };
    case CHANGE_POINT:
      return {
        ...state,
        points: updateEditPolygonPoints(
          state.points.map(p =>
            p.id === action.payload.id
              ? {
                ...p,
                position: action.payload.position,
              }
              : p
          ),
          state.isDrawingMode,
          action.payload.id
        ),
      };
    case DELETE_POINT:
      return {
        ...state,
        points: updateEditPolygonPoints(
          state.points.filter(p => p.id !== action.payload),
          state.isDrawingMode
        ),
        // there is no reason to keep drawing disabled without a valid polygon
        // be noted, that for 'length' evaluation, state.points are still before actual point deletion
        isDrawingMode: state.isDrawingMode || state.points.length <= MIN_POLYGON_LENGTH,
      };
    case MAKE_POINT_REAL:
      return {
        ...state,
        points: updateEditPolygonPoints(
          state.points.map(p => (p.id === action.payload ? { ...p, type: 'real' } : p)),
          state.isDrawingMode
        ),
      };
    case CLEAR_ALL_POINTS:
      return {
        ...state,
        points: [],
        isDrawingMode: true,
      };
    case TOGGLE_WELL_SELECTION:
      return {
        ...state,
        selectedWellIds: state.selectedWellIds.includes(action.payload)
          ? state.selectedWellIds.filter(wellId => wellId !== action.payload)
          : [...state.selectedWellIds, action.payload],
      };
    case FIND_WELLS_START:
      return {
        ...state,
        isLoading: true,
        loadingError: undefined,
        selectedWellIds: [],
      };
    case FIND_WELLS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        wells: action.payload.map(
          (item): Well => ({
            resourceId: item.resource_id,
            facilityName: item.facility_name,
            location: GeoJSON.coordsToLatLng(item.location.coordinates),
          })
        ),
      };
    case FIND_WELLS_FAIL:
      return {
        ...state,
        isLoading: false,
        loadingError: action.payload,
      };
    case CLEAR_SEARCH_ERROR:
      return {
        ...state,
        isLoading: false,
        loadingError: undefined,
      };
    default:
      return state;
  }
};

/**
 * Every real point will be followed by a fake one, needed for edit purposes
 * Or stripped from any of them for an active drawing mode
 * Complexity of the method is dictated by a need to preserve existing valid 'edit' points
 *
 * FIXME: currentyDraggingId is used, becase I haven't found a working way
 * to convert an 'edit' point into real one on its very first drag event without drag being aborted
 * @param points current polygon points, you wish to make consistent with editing feature
 * @param isDrawingMode
 * @param currentyDraggingId used to stabilize algorythm when an 'edit' point is dragged
 */
function updateEditPolygonPoints(
  points: PolygonPoint[],
  isDrawingMode: boolean,
  currentyDraggingId?: string
): PolygonPoint[] {
  if (isDrawingMode) {
    // 'edit' points are used only for editing of a complete polygon;
    return points.filter(p => p.type === 'real');
  }

  const realPoints = points.filter(p => p.type === 'real');
  // any sequence of 'edit' points should be reduced to a single point
  const noEditSequences = points.filter(
    (p, i) => p.type === 'real' || (points[i - 1] !== undefined && points[i - 1].type === 'real')
  );

  // now fill gaps: every 'real' point should be followed by an 'edit' one
  // and adjust 'edit' points - they should always be right in the middle between two real points
  return realPoints.reduce((result: PolygonPoint[], realPoint, i): PolygonPoint[] => {
    const nextRealPoint = realPoints[(i + 1) % realPoints.length];
    const editPointPosition = new LatLng(
      (realPoint.position.lat + nextRealPoint.position.lat) / 2,
      (realPoint.position.lng + nextRealPoint.position.lng) / 2
    );

    // check, what we currently have next to the real point
    let editPoint = noEditSequences[i * 2 + 1];
    if (editPoint === undefined || editPoint.type !== 'edit') {
      // edit point is missing, we should create one
      editPoint = createPolygonPoint(editPointPosition, 'edit');
    } else {
      // an 'edit' point is on its place, but we still need to update its position
      // FIXME: we will skip the update if this is the currently dragged point
      editPoint.position =
        editPoint.id === currentyDraggingId ? editPoint.position : editPointPosition;
    }
    return [...result, realPoint, editPoint];
  }, []);
}

let pointsCounter = 0;
function createPolygonPoint(position: LatLng, type: PolygonPoint['type']): PolygonPoint {
  return {
    id: `p${pointsCounter++}`,
    type,
    position,
  };
}
