import {API} from "aws-amplify";

export interface FindWellsResponse {
    wells: Well[];
}

/* eslint-disable @typescript-eslint/camelcase */
interface Well {
    resource_id: string;
    facility_name: string;
    location: {
        coordinates: [number, number];
        // probably it should be of GeoJsonTypes type and possible to parse using library,
        // but from api we receive 'point', not 'Point' and parsing fails.
        // anyway, we have no need in such a deep integration, a lat-lng pair will sufice
        type: string;
    };
}

/* eslint-enable @typescript-eslint/camelcase */

/**
 * Returns a list of wells, found by a given name
 * no pagination is implemented, results are limited by the backend (99 atm)
 * Error handler is included
 * @param {string} wellName
 */
export function findWellsByName(wellName: string): Promise<FindWellsResponse> {
    return API.get('api', `/api/sample-app/v1/find/wells_by_name?well_name=${wellName}`, {
        headers: {
            'data-partition-id': 'opendes'
        }
    })
}

export interface FindWellboresResponse {
    wellbores: Wellbore[];
}

interface Wellbore {
    resource_id: string;
    facility_name: string;
}

/**
 * Returns a collection of wellbores for a given well
 * Error handler is included
 * @param wellId
 */
export function findWellbores(wellId: string): Promise<FindWellboresResponse> {
    return API.get('api', `/api/sample-app/v1/find/wellbores_by_well_id?well_id=${wellId}`, {
        headers: {
            'data-partition-id': 'opendes'
        }
    })
}
