import {Card, CardContent} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React from "react";

export default function OperateInProduction() {
    return (
        <div>
            <Card>
                <CardContent>
                    <Typography variant="h4">
                        Operate OSDU™ Data Platform in Production
                    </Typography>
                    <br/>
                    <Typography component="div">
                        One of the major components to evaluating the OSDU™ Data Platform is determining a model for how
						your organization will operate an OSDU Data Platform in production. There are a number of factors
						to consider that all present different cost, security, accessibility, speed, and support trade-offs.
                    </Typography>
                    <br/>
                    <br/>

                    <Typography variant="h5">
                        OSDU Instances
                    </Typography>
                    <br/>
                    <Typography component="div">
                        One of the biggest decisions each company will have to make is how many instances of the OSDU™ 
                        Data Platform will be supported. For example: will these instances be available in multiple regions,
                        will they be separated into different accounts, will they possibly be grouped by environments to
                        separate them based on where they are in the software development lifecycle? Determining the
                        answer to these questions can be difficult ahead of time which leads to the larger question of,
                        should the OSDU instances have the ability to be dynamically deployed inside an
                        environment/account/region landscape that can be modified to meet your organizations needs at
                        that time? If the answers to these questions sounds difficult, working with a service provider
                        that has flexibility in their model may be the right choice for your organization
                    </Typography>
                    <br/>
                    <br/>

                    <Typography variant="h5">
                        Deployment Pipeline
                    </Typography>
                    <br/>
                    <Typography component="div">
                        An important aspect of running your OSDU™ Data Platform in production is to have a stable deployment
                        pipeline that users trust to update the platform without causing unnecessary downtime.
                        Investment into the development of this pipeline takes time and money and should be factored
                        into each company’s analysis. If your organization is not interested in this type of investment
                        and would instead like to stay focused on the business use cases within the OSDU™ Data Platform, there
                        may be service providers such as 47lining that can handle this for you.
                    </Typography>
                    <br/>
                    <br/>

                    <Typography variant="h5">
                        Support and Issue Resolution
                    </Typography>
                    <br/>
                    <Typography component="div">
                        The OSDU™ Data Platform and its deployment and operations processes will have issues from time to
                        time. How will these bugs be resolved? Who is responsible for finding, tracking, and resolving
                        them? Will a support team within your organization need to come up to speed to support your
                        users and make sure they are unblocked by such issues. This can be a costly investment that your
                        organization may not be best positioned to pursue. Relying on a service provider such as
                        47Lining to do this on your behalf can save your company from having to establish a new support
                        structure for OSDU Data Platform operations.
                    </Typography>
                    <br/>
                    <br/>

                    <Typography variant="h5">
                        Account Management
                    </Typography>
                    <br/>
                    <Typography component="div">
                        A requirement of your organization will likely be that you will have to enable security scans
                        and audits by various departments within your Enterprise in order to meet security and
                        compliance guidelines. Enabling this kind of activity must be considered when evaluating the
						OSDU Data Platform. In the 47Lining service provider model, this is already factored in and
						will allow for centralized logging and security accounts that members of the IT departments
						can be granted access into without compromising the integrity of your data.
                    </Typography>
                    <br/>
                    <br/>
                </CardContent>
            </Card>
        </div>
    )
}