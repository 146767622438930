import React from 'react';
import Cards from "../../components/cards/Cards";
import ImageCard from "../../style/components/UI/Card/ImageCard";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles(theme => (
    {
        title: {
            paddingBottom: theme.spacing(2)
        },
    }
));

function Services() {

    const classes = useStyles();


    const base = process.env.REACT_APP_BASE_API_URL_DISPLAY;

    const ITEMS = [
        {
            title: "Authentication",
            description:
                <>
                    Authentication is carried out by both the OSDU Data Platform and the Cloud Provider (AWS) Services. The
					47Lining Enterprise PaaS - Preview Deployment uses <a className="text-link" rel="noopener noreferrer" target="_blank"
                                            href="https://docs.aws.amazon.com/cognito/latest/developerguide/amazon-cognito-user-pools-authentication-flow.html">
                    AWS Cognito</a> for Authentication. It starts with the user passing in their
                    credentials and authorization tokens are generated to use in all their future requests. Users can
                    also copy the AccessToken to the clipboard in the Management Console provided by 47Lining.
                    <br/>
                    <br/>
                </>,
            hideDivider: true
        },
        {
            title: "Legal",
            description:
                <>
                    Data compliance is largely governed through the Records in the storage service. Though there is an
                    independent legal service and LegalTags entity, these offer no compliance by themselves. Records
                    have a Legal section in their schema and this is where the compliance is enforced. However, clients
                    must still make sure they are using the Record service correctly to remain compliant.

                    <br/>
                    <br/>
                    <a className='text-link' rel="noopener noreferrer" target="_blank"
                       href={base + '/api/legal/v1/swagger'}>Legal API</a> and&nbsp;
                    <a className='text-link' rel="noopener noreferrer" target="_blank"
                       href="https://community.opengroup.org/osdu/platform/security-and-compliance/legal/-/blob/master/docs/tutorial/ComplianceService.md">
                        Legal Documentation</a>
                    <br/>
                    <br/>
                </>,
            hideDivider: true
        },
        {
            title: "Entitlements",
            description:
                <>
                    The Entitlements service is used to enable authorization in Data Ecosystem. It relies on Cloud
                    Provider groups as an infrastructure that ties into Cloud Provider IAM making AWS native
                    authorization a possibility. The service allows for the creation of Cloud Provider groups. A group
                    name defines a permission. Users who are added to that group obtain that permission. For each group
                    users can either be added as an OWNER or a MEMBER. The only difference being if a user is an OWNER
                    of a group, then they can manage the members of that group.
                    <br/>
                    <br/>
                </>,
            hideDivider: true
        },
        {
            title: "Storage",
            description:
                <>
                    The Storage Service provides a set of APIs to manage the entire metadata life-cycle of ingesting
                    metadata into the Data Ecosystem (persistence), modification, deletion, versioning and data schema.

                    <a className='text-link' rel="noopener noreferrer" target="_blank"
                       href={base + '/api/storage/v2/swagger'}>Storage</a>
                    <br/>
                    <br/>
                    <a className='text-link' rel="noopener noreferrer" target="_blank"
                       href={base + '/api/storage/v2/swagger'}>Storage API</a> and&nbsp;
                    <a className='text-link' rel="noopener noreferrer" target="_blank"
                       href="https://community.opengroup.org/osdu/platform/system/storage/-/blob/master/docs/tutorial/StorageService.md">
                        Storage Documentation</a>
                    <br/>
                    <br/>
                </>,
            hideDivider: true
        },
        {
            title: "Search",
            description:
                <>
                    The Search API provides a mechanism for indexing documents that contain structured data. Users can
                    search an index, and organize and present search results. Documents and indexes are saved in a
                    separate persistent store optimized for search operations. The Search API can index any number of
                    documents. <br/><br/>

                    The API supports full text search on string fields, range queries on date, numeric or string fields
                    etc. along with geo-spatial search.
                    <br/>
                    <br/>
                    <a className='text-link' rel="noopener noreferrer" target="_blank"
                       href={base + '/api/search/v2/swagger'}>Search API</a> and&nbsp;
                    <a className='text-link' rel="noopener noreferrer" target="_blank"
                       href="https://community.opengroup.org/osdu/platform/system/search-service/-/blob/master/docs/tutorial/SearchService.md">
                        Search Documentation</a>
                    <br/>
                    <br/>
                </>,
            hideDivider: true
        }, {
            title: "Delivery",
            description:
                <>
                    The Delivery service provides a general-purpose delivery mechanism which supports work product
                    components beyond files. In the case of files, it wrappers the File service. The Delivery Service
                    can be used to deliver metadata and data to external applications.
                    <br/>
                    <br/>
                    <a className='text-link' rel="noopener noreferrer" target="_blank"
                       href={base + '/api/delivery/v2/swagger'}>Delivery API</a> and&nbsp;
                    <a className='text-link' rel="noopener noreferrer" target="_blank"
                       href="https://community.opengroup.org/osdu/documentation/-/wikis/OSDU-(C)/Design-and-Implementation/Ingestion-and-Enrichment-Detail/R2-Delivery">
                        Delivery Documentation</a>
                    <br/>
                    <br/>
                </>,
            hideDivider: true
        }]
    return (
        <>
            <div className={classes.title}>
                <ImageCard hideDivider={true} title='OSDU™ Services' description={<>
                    47Lining has included all of the standard services provided by the OSDU Data Platform.
                    This includes the components that allow the OSDU Data Platform to be deployed.&nbsp;
                    <a className="text-link" rel="noopener noreferrer" target="_blank"
                       href="https://community.opengroup.org/osdu/documentation/-/wikis/Releases/R2.0/Services">OSDU
                        Services and APIs</a>

                </>}/>
            </div>
            <div>
                <Cards items={ITEMS}>
                </Cards>
            </div>
        </>

    )
}


export default Services;