import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { FormControl, TextField, FormHelperText,
    Button, Typography, Box
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => (
    {
        formControl: {
            display: 'block',
            padding: 0
        },
        formTitle: {
            marginBottom: theme.spacing(2),
            paddingTop: theme.spacing(1.5)
        },
        select: {
            marginBottom: theme.spacing(4),
            width: '100%',
            '&:last-of-type': {
                marginBottom: theme.spacing(0),
            }
        },
        input: {
            marginBottom: theme.spacing(4),
            width: '100%'
        },
        textFieldValue: {
            fontSize: '14px',
            lineHeight: '19px'
        },
        textFieldLabel: {
            color: theme.palette.primary.main
        },
        helperText: {
            marginBottom: theme.spacing(2),
        },
        btnContainer : {
            display : 'flex',
            justifyContent:'space-between'
        }
    }
))

const CreateEnvironment = (props) => {   
    const classes = useStyles();
    return (
        <>
            <FormControl className={classes.formControl}>
                <Typography className={classes.formTitle}
                    variant='h6'>{props.title}</Typography>
                <TextField id="environment-name"
                    className={clsx(classes.textField, classes.input)}
                    label="Environment name"
                    variant="filled" />
                <Autocomplete
                    id="region-select"
                    className={classes.select}
                    options={props.regions.options}
                    getOptionLabel={(option) => option.name}
                    defaultValue={props.regions.options[0]}
                    closeIcon={null}
                    fullWidth
                    renderInput={(params) =>
                        <TextField {...params} label="Region"
                            InputLabelProps={{ className: classes.textFieldLabel }}
                            variant="filled" className={classes.textFieldValue} />}
                />
                <Autocomplete
                    id="account-select"
                    className={classes.select}
                    options={props.accounts.options}
                    getOptionLabel={(option) => option.name}
                    defaultValue={props.accounts.options[0]}
                    closeIcon={null}
                    fullWidth
                    renderInput={(params) =>
                        <TextField {...params} label="Account"
                            InputLabelProps={{ className: classes.textFieldLabel }}
                            variant="filled" className={classes.textFieldValue} />}
                />
                <FormHelperText id="helper-text"
                    className={classes.helperText}
                    color={props.color}>
                    This is a description to give the user more context.
            </FormHelperText>
                <Box className={classes.btnContainer}>
                    <Button variant='contained'
                    color='primary'
                    onClick={props.handleSubmit}
                    children='Create' />
                    <Button variant='contained'
                    color='secondary'
                    onClick={props.handleCancel}
                    children='Cancel' />
                </Box>                   
            </FormControl>                     
        </>
    )
}

export default CreateEnvironment;
CreateEnvironment.propTypes = {
    title : PropTypes.string,
    handleSubmit: PropTypes.func,
    handleCancel : PropTypes.func,
    regions: PropTypes.object,
    accounts: PropTypes.object
}





