import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {MobileStepper, Button, Box} from '@material-ui/core';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

const useStyles = makeStyles({
    root: {
        display:'flex',
        flexDirection:'column',
        justifyContent:'space-between',
        height: '100%',
        
    },
    content: {
        flexGrow: 1,
    },
    stepper: {     
      
    },
  });
 const ProgressStepper = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const [stepCount,getSteps] = useState(0);
    const [disableStep, setDisable] = useState(0);
    const [activeStep, setActiveStep] = useState(0);    
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };  
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    useEffect(()=>{
        getSteps(props.children.length);    
        setDisable(props.children.length - 1);
    },[props.children]);  

    return (
        <Box className={classes.root}> 
        <Box className={classes.content}>
            {props.children[activeStep]}    
        </Box>   
        <MobileStepper
        className={classes.stepper}
            variant="text"
            steps={stepCount}
            position="static"
            activeStep={activeStep}       
            nextButton={
            <Button size="small" 
                variant="text" 
                color="primary" 
                onClick={handleNext} disabled={activeStep === disableStep}>
                Next
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </Button>
            }
            backButton={
            <Button size="small" 
                variant="text"
                color="primary"
                onClick={handleBack} disabled={activeStep === 0}>
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                Back
            </Button>
            }
        />
      </Box>
    );
  }
  export default ProgressStepper;
  ProgressStepper.propTypes = {
    children: PropTypes.arrayOf(Node)
  }