import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Grid, MenuItem, Box, Typography, IconButton,ClickAwayListener,
  useTheme, useMediaQuery} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import {MenuPopper} from '../../UI';
const useStyles = makeStyles(theme => ({
  popper: {
    backgroundColor: theme.palette.white,
    padding: theme.spacing(2),
    borderRadius: '2px',
    zIndex: theme.zIndex.popper,
    boxShadow: `0px 6px 10px rgba(0, 0, 0, 0.14)`
  },
  menuWrapper: {
    display: 'flex',
    flexGrow: 1,
    alignContent: 'center',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    color: theme.palette.white,
    borderTop: `1px solid ${theme.palette.grey2}`,
    [theme.breakpoints.up('lg')]: {
      paddingTop: 0,
      paddingBottom: 0,
      borderTopWidth: 0,
      display: 'flex',
      position: 'relative'
    }
  },
  userName: {
    color: theme.palette.white,
    flexGrow: 1,
    alignSelf: 'center',
    marginRight: theme.spacing(2)
  },
  userIcon: {
    alignSelf: 'center'
  },
  iconButton: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  menuContainer: {
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('lg')] : {
      paddingLeft: 0,
      paddingBottom: 0
    }
  },
  menuItem: {
    color: theme.palette.white,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      color: '#30373E'
    }
  },
  menuItemText: {
    marginLeft: theme.spacing(2),
  }
}));
const UserMenu = (props) => {
  const classes = useStyles();
  const theme  = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = event => {
    anchorEl === null ? setAnchorEl(event.currentTarget) : setAnchorEl(null);
  };

  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  });

  const handleClose = () =>  setAnchorEl(null);
  const handleClickAway = () => setAnchorEl(null);

  const handleMenuSelect = (menuHandler) => {
    menuHandler();
    handleClose();
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
     <Grid item container className={classes.menuWrapper}>
      <Typography
        variant="body2"
        className={classes.userName}>{props.userName}
      </Typography>
      <AccountCircleIcon
              variant='outlined'
              className={classes.userIcon} />
      <IconButton
          aria-controls="user-menu"
          color='inherit'
          aria-haspopup="true"
          className={classes.iconButton}
          onClick={handleClick}>
          { isDesktop ? <ArrowDropDownIcon /> : <ArrowRightIcon/>}
      </IconButton>
      <MenuPopper anchorEl={anchorEl}
        handleClose = {handleClose}
        placement = 'bottom-end'
        children = {
          <Box className = {classes.menuContainer}>
            {props.menuOptions.map(option => {
          return (
            <MenuItem
              className = {classes.menuItem}
              onClick={() => handleMenuSelect(option.action)}
              onChange={props.handleChange}
              dense key={option.name}
              divider={option.divider}
              disabled={option.disabled}>
              {option.icon}  <span className={classes.menuItemText}>{option.name}</span>
            </MenuItem>
          )
        })}
          </Box>
      }
      >
      </MenuPopper>

    </Grid >
    </ClickAwayListener>

  );
}

export default UserMenu;
UserMenu.propTypes = {
  handleChange: PropTypes.func.isRequired,
  userName: PropTypes.string.isRequired,
  menuOptions: PropTypes.array.isRequired
}