import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, IconButton, TextareaAutosize,Tooltip  } from '@material-ui/core';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';

const useStyles = makeStyles(theme => ({
    heading : {
        marginBottom: theme.spacing(3),
        fontWeight: theme.typography.fontWeightMedium
    },
    dflex : {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(3)
    },
    description : {      
        color: theme.palette.grey5,
        alignSelf :'center'
    },
    content : {      
        maxHeight: theme.spacing(22),
        maxWidth: theme.spacing(40),
        overflowY: 'auto',
        padding: theme.spacing(2),  
        background: theme.palette.grey2,
        borderColor: 'transparent',
        fontFamily: 'Roboto',
        fontSize: '16px',
        lineHeight: '19px',
        letterSpacing: 0.15,
        '&:focus':{
            outlineColor: `${theme.palette.grey2}`,
        }
    }    
}));

const MessageBox = (props) => {
    const classes = useStyles();
    const [toolTipOpen,setToolTipState] = useState(false);


    const handleCopy = () => {
        const text = document.getElementById('text-content');
         text.select();
         text.setSelectionRange(0,99999);
         document.execCommand('copy');
         setToolTipState(true);
    }
    return (
        <Box className={classes.root}>
            <Typography
               className = {classes.heading}
                component='h3' variant='h5'
                children='Error' />
            <Box className= {classes.dflex}>
                <Typography
                component='h4' variant='body1'
                className = {classes.description}
                children={props.description} />
                <Tooltip open = {toolTipOpen} title="Copied" placement="bottom" disableFocusListener >
                    <IconButton 
                    onClick = {handleCopy}
                    children = {<FileCopyOutlinedIcon color='primary'/>}
                    />  
                </Tooltip>                             
            </Box>         
            <TextareaAutosize id='text-content' 
                rowsMin={3}
                rowsMax={10}
                className={classes.content} 
                defaultValue =  {props.content}    
            />                    
        </Box>
    )
}

export default MessageBox;



