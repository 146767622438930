import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import {Scrollbars} from 'react-custom-scrollbars';
import {
  Box,
  Grid,
  Paper,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography
} from '@material-ui/core';
import TableFilter from './TableFilter';
import TableActionWithDialog from './TableActionDialog';
import MainTableRefresh from './TableRefresh.js';

/** Top row of main table */
function EnhancedTableHead(props) {
    const {classes, columnInfo, onRequestSort, order, orderBy} = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };
    // this is to add the "disable padding" property. This can probably be replaced with a condition when rendering the cells
    const headCells = columnInfo.columns.map((column, index) => {
        return {
            id: column.id,
            numeric: column.numeric,
            disablePadding: index === 0,
            label: column.label
        };
    });

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="none">
                </TableCell>
                {headCells.map(headCell => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    columnInfo: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
};

const useStyles = makeStyles(theme => ({
    icon: {
        textAlign: 'right',
        [theme.breakpoints.down('430')]: {
            display: 'none'
        }
    },
    tableTitleContainer: {
        flexWrap: 'nowrap',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    title: {
        color: theme.palette.grey5,
        marginRight: theme.spacing(2)
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        backgroundColor: theme.palette.white
    },
    table: {
        marginTop: theme.spacing(2)
    },

    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    alignCenter: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    tableHeadContainer: {
        flexWrap: 'nowrap',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        backgroundColor: theme.palette.background.page,
        [theme.breakpoints.down('851')]: {
            display: 'block'
        }
    },
    tableActionContainer: {
        flexWrap: 'nowrap',
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap'
        }
    },
    filterContainer: {
        marginRight: theme.spacing(2)
    },
    actionContainer: {
        paddingTop: '4px',
    },
    paginationContainer: {
        justifyContent: 'flex-end'
    },
    tableControlContainer: {
        display: 'flex',
        flex: 1,
        justifyContent: 'flex-end'
    },
    tableCell: {
        whiteSpace: 'nowrap',
        [theme.breakpoints.up('lg')]: {
            paddingLeft: theme.spacing(3)
        }
    }

}));

/** Main table - including action dropdown and search box */
export default function MainTable(props) {
    const {
        rows, columnInfo, selectedId, actionMenuOptions, handleSelectedChange, page,
        isNextPage, handleChangePage, rowsPerPage, /*  handleChangeRowsPerPage,*/ order,
        orderBy, handleRequestSort, handleRefreshClick, handleSearchChange, title,
        contentScrollHeight = 0
    } = props;

    const classes = useStyles();

    const getRowCells = (row, labelId) => {
        return columnInfo.columns.map((column, index) => {
            let tableCell = <TableCell className={classes.tableCell} key={column.id}>{row[column.id]}</TableCell>;
            if (index === 0) {
                tableCell = <TableCell className={classes.tableCell} key={column.id}
                                       component="th" id={labelId} scope="row"
                                       padding="none"> {row[column.id]} </TableCell>;
            }
            return tableCell;
        });
    }

    return (
        <div className={classes.root}>
            <Paper className={classes.paper} elevation={0}>
                <Grid container className={clsx(classes.tableHeadContainer)}>
                    <Grid item className={clsx(classes.tableTitleContainer)}>
                        <Typography variant='h6' component="h2" className={classes.title}>{title}</Typography>
                    </Grid>
                    <Grid item container className={clsx(classes.tableActionContainer)}>
                        <Grid item className={classes.filterContainer}>
                            <TableFilter handleSearchChange={handleSearchChange}/>
                        </Grid>
                        <Grid item className={clsx(classes.alignCenter, classes.actionMenuContainer)}>
                            <TableActionWithDialog
                                actionMenuOptions={actionMenuOptions}
                                dialogOpen={props.dialogOpen}
                                closeDialogHandler={props.handleDialogClose}
                                onMenuClick={props.openDialogBox}
                            />
                        </Grid>
                        <Grid item className={classes.tableControlContainer}>
                            <Box className={classes.icon}>
                                <MainTableRefresh handleRefreshClick={handleRefreshClick}/>
                            </Box>
                            <TablePagination
                                rowsPerPageOptions={[rowsPerPage]}
                                component="div"
                                count={rows.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: {'aria-label': 'rows per page'},
                                    native: true,
                                }}
                                onChangePage={handleChangePage}
                                // labelDisplayedRows={} - //TODO - use this to customize "Page x of x" text - https://material-ui.com/api/table-pagination/
                                backIconButtonProps={{disabled: page === 0}}
                                nextIconButtonProps={{disabled: !isNextPage}}  //disable next page icon if there is no next page
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Scrollbars style={{width: '100%', height: `${contentScrollHeight}px`}}>
                    <TableContainer className={classes.tableContainer}>
                        <Table size="small"
                               id={props.id}
                               className={classes.table}
                               aria-labelledby="tableTitle"
                               aria-label="enhanced table">
                            <EnhancedTableHead
                                classes={classes}
                                columnInfo={columnInfo}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}/>
                            <TableBody>
                                {rows.map((row, index) => {
                                    const isItemSelected = selectedId === row[columnInfo.uniqueKey];
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <TableRow
                                            hover
                                            onClick={() => handleSelectedChange(row[columnInfo.uniqueKey])} //TODO - ignore click if row has already been selected
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row[columnInfo.uniqueKey]}
                                            selected={isItemSelected}>
                                            <TableCell
                                                className={classes.tableCell}
                                                padding='none'
                                                size='small'>
                                                <Radio
                                                    checked={isItemSelected}
                                                    color='primary'
                                                    inputProps={{'aria-labelledby': labelId}}/>
                                            </TableCell>
                                            {getRowCells(row, labelId)}
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Scrollbars>
            </Paper>
        </div>
    );
}

MainTable.propTypes = {
    title: PropTypes.string,
    rows: PropTypes.array.isRequired,
    columnInfo: PropTypes.object.isRequired,
    selectedId: PropTypes.string.isRequired,
    handleSelectedChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    handleChangePage: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    handleRequestSort: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired
};
