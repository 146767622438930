import React, {ChangeEvent, FormEvent, useEffect, useState} from 'react';
import './styles.css';
import {Hint, Loader} from '../../../components/shared';
import {FoundWell} from '../found-well';
import {WellSearchResponse} from '../../../store/well-search';
import {TrajectoryToDraw} from '../../../store/trajectory';
import {TextField} from "@material-ui/core";
import useDebounce from "../../../../../hooks/useDebounce";

export interface SearchProps {
    /**
     * a search value, stored in a redux state
     * though we will not update in on every input change,
     * we will keep an eye on it and make sure to use it if updated
     */
    storedSearchName: string;

    /** its text will be displayed instead of results list */
    searchError?: Error;

    /** flag to toggle an amazing loader */
    areWellsSearching: boolean;

    /**
     * with this knowledge we have an ability to inform a user that
     * the search itself was successful, just fruitless :)
     */
    areWellsSearched: boolean;

    /** we'll draw a cool list out of them */
    foundWells: WellSearchResponse[];

    /**
     * trajectories data from a neighbor trajectory state.
     * it will be passed further to a FoundWell
     */
    selectedTrajectories: TrajectoryToDraw[];

    /** this wellId marks to which well trajectories data belongs */
    selectedWell: string;

    /** search form submit */
    onSubmit: (name: string) => void;

    /** a user's request to load wellbores for a given well to look at them */
    onLoadWellbores: (wellId: string) => void;

    /** a user's request to load trajectory data to visualize it */
    onFetchTrajectory: (wellId: string, wellboreId: string) => void;

    /** a user's decree that she is done with the trajectory's visualization */
    onUnselectTrajectory: (wellboreId: string) => void;
}

const noSearchHint = 'Results will be displayed here';
const noDataHint = 'No wells found';
const cannotFindWellsMsg = 'Cannot find wells';

export function Search({
                           onSubmit,
                           storedSearchName,
                           onLoadWellbores,
                           onFetchTrajectory,
                           onUnselectTrajectory,
                           searchError,
                           areWellsSearching,
                           areWellsSearched,
                           foundWells,
                           selectedWell,
                           selectedTrajectories,
                       }: SearchProps) {
    const [searchName, setSearchName] = useState(storedSearchName);
    const debouncedSearchName = useDebounce(searchName, 500);

    const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
        // note, that this value does not sync with the store
        // store will be updated only on an actual search action
        setSearchName(event.target.value);
    };

    const handleSubmit = (event: FormEvent | MouseEvent | null = null) => {
        if (event) event.preventDefault();
        onSubmit(searchName);
    };

    useEffect(() => {
        // without this update, value from the store used only once.
        // and never realy watched, creating an illusion of a coherent behavior
        setSearchName(storedSearchName);
    }, [storedSearchName]);

    useEffect(() => {
            handleSubmit()
        },
        // eslint-disable-next-line
        [debouncedSearchName]);

    return (
        <div className="search">
            {/* a search form at the top */}
            <div className="search__area">
                <TextField className="search__area-input"
                           label='Well name'
                           onChange={handleSearchChange}
                           value={searchName}
                />
            </div>
            {/* a result representing area right under it */}
            <div className="search__well-area">
                {areWellsSearching ? (
                    // a progress, an anticipation
                    <Loader/>
                ) : searchError ? (
                    // an error will be drawn here
                    <Hint title={cannotFindWellsMsg} subTitle={String(searchError)}/>
                ) : areWellsSearched ? (
                    // Successful search, two options now:
                    foundWells.length === 0 ? (
                        // nothing realy found
                        // an honest descriptive message is much better that a blank space
                        <Hint subTitle={noDataHint}/>
                    ) : (
                        // success, wells will be drawn
                        foundWells.map(well => (
                            <FoundWell
                                key={well.resourceId}
                                well={well}
                                selectedTrajectories={well.resourceId === selectedWell ? selectedTrajectories : []}
                                onLoadWellbores={onLoadWellbores}
                                onFetchTrajectory={onFetchTrajectory}
                                onUnselectTrajectory={onUnselectTrajectory}
                            />
                        ))
                    )
                ) : (
                    // it's a very beginning: nothing done right, nothing went wrong
                    // a perfect time to give a hint ';..;'
                    <Hint subTitle={noSearchHint}/>
                )}
            </div>
        </div>
    );
}
