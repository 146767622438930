import {API} from "aws-amplify";

type Formation = string;

/* eslint-disable @typescript-eslint/camelcase */
interface Well {
    resource_id: string;
    facility_name: string;
    marker_depths: number[];
    formations: Formation[];
}

/* eslint-enable @typescript-eslint/camelcase */

export type CrossSectionResponse = Well[];

/**
 * Returns an object with data wich needs to create cross-section chart
 * This data includes an array of wells and an array of formations
 * Error handler is included
 * @param wellIds
 */



export function fetchWellMarkers(wellIds: string[]): Promise<CrossSectionResponse> {
    return API.get('api', `/api/sample-app/v1/fetch/markers_by_well_ids?well_ids=${wellIds.join(',')}`, {
        headers: {
            'data-partition-id': 'opendes'
        }
    })
}
