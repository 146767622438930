import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Box, Card, CardContent, Divider, Typography} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    title: {
        fontWeight: 700,
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    body: {
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1)
    },
    divider: {
        borderTop: `2px solid ${theme.palette.secondary.main}`,
        width: '100%'
    },
    image: {
        width: '100%',
        '& img': {
            maxWidth: '100%',
            maxHeight: '200px', // TODO: ?
            width: '100%',
            height: 'auto'
        }
    }
}))

const ImageCard = (props) => {
    const classes = useStyles();

    const ConditionalDivider = () => props.hideDivider ? <span></span> :
        <Divider className={classes.divider} variant='fullWidth'/>

    return (
        <Card>
            <CardContent>
                <Box className={classes.image}>
                    {props.image}
                </Box>
                <Typography variant="h5" component="h2"
                            className={classes.title}
                            color='primary'>{props.title}
                </Typography>
                <Typography variant="body1" component="p"
                            className={classes.body}>
                    {props.description}
                </Typography>
                <ConditionalDivider/>
            </CardContent>
        </Card>
    )
}

export default ImageCard;
