import React from 'react';
import PropTypes from 'prop-types';
import {Dialog, DialogContent, IconButton} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: `rgba(4, 61, 109, .1)`
    },
    content: {
      padding: theme.spacing(3),
      position: 'relative'
    },
    closeIcon: {
      position: "absolute",
      top: theme.spacing(1),
      right: theme.spacing(1),
      zIndex: 1
    }
}));

const ScrollDialog = ({scroll = 'body', open, 
    fullWidth = false, fullScreen = false,children, closeDialog ,  ...rest }) => {    
    const classes = useStyles();   
    return (            
        <Dialog          
          className = {classes.root}
          open={open}
          onClose={closeDialog}        
          scroll={scroll}
          fullWidth = {fullWidth}
          fullScreen = {fullScreen}          
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          {...rest}
         >
          <DialogContent className={classes.content}>
            <IconButton 
               className = {classes.closeIcon}
               onClick = {closeDialog}
               children = {<CloseIcon/>}
            />
            {children}
          </DialogContent>         
        </Dialog>     
    );
  }

  export default ScrollDialog;
  ScrollDialog.propTypes = {
      scroll: PropTypes.string,     
      children: PropTypes.node,
      closeDialog: PropTypes.func.isRequired
  }