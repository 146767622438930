import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import {useSelector} from "react-redux";
import {selectFooterLinks} from "../../../state/shared";

const useStyles = makeStyles(theme => ({
    footer: {
        alignSelf: 'flex-end',
        marginTop: theme.spacing(2)
    },
    footerLink: {
        marginLeft: '20px',
        fontSize: '12px',
        letterSpacing: '0.22px',
        lineHeight: '20px'
    },
    copyrightText: {
        marginLeft: '20px',
        fontSize: '12px',
        letterSpacing: '0.22px',
        lineHeight: '20px'
    }
}));

const Footer = (props) => {
    const classes = useStyles();
    const footerLinks = useSelector(selectFooterLinks);

    return (
        <Grid container className={classes.footer}>
            <Grid item>
                {footerLinks.map(footerLink => {
                    return (
                        <Link
                            target={'_blank'}
                            href={footerLink.path}
                            className={classes.footerLink}
                            key={footerLink.text}
                        >
                            {footerLink.text}
                        </Link>
                    );
                })}
            </Grid>
            <Grid item>
                <Typography className={classes.copyrightText} gutterBottom>
                    {String.fromCharCode(169) + 'Hitachi Vantara LLC 2020. All Rights Reserved. OSDU is a trademark of The Open Group.'}
                </Typography>
            </Grid>
        </Grid>
    );
}

export default Footer;