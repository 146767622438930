import {CrossSectionResponse} from '../../api/cross-section.api';

export const LOAD_WELLS_DATA_START = 'LOAD_WELLS_DATA_START';
export const LOAD_WELLS_DATA_SUCCESS = 'LOAD_WELLS_DATA_SUCCESS';
export const LOAD_WELLS_DATA_FAIL = 'LOAD_WELLS_DATA_FAIL';

export interface LoadWellsDataStartAction {
    type: typeof LOAD_WELLS_DATA_START;
    payload: string[];
}

export interface LoadWellsDataSuccessAction {
    type: typeof LOAD_WELLS_DATA_SUCCESS;
    payload: CrossSectionResponse;
}

export interface LoadWellsDataFailAction {
    type: typeof LOAD_WELLS_DATA_FAIL;
    payload: Error;
}

export type CrossSectionActionTypes =
    | LoadWellsDataStartAction
    | LoadWellsDataSuccessAction
    | LoadWellsDataFailAction;
