import React from 'react';


function NotFound() {

    return (
        <h1>
            This page is not yet implemented but we are working on it.
        </h1>
    )
}


export default NotFound;