import React from 'react';
import './styles.css';
import {ControlPanel, Map, SelectedWells} from '../../components/map';
import {Well} from '../../store/map';

export interface MapPageProps {
    selectedWells: Well[];
}

/**
 * Creates a map which includes a map view, control panel with buttons,
 * draw/edit polygon (the area that creates boundaries for finding wells),
 * select found wells on the map and passing these well ids to the draw cross-section page
 */
export function MapPage({selectedWells}: MapPageProps) {
    return (
        <div className="map">
            <Map/>
            {selectedWells.length !== 0 && <SelectedWells className="map__selected-wells" wells={selectedWells}/>}
            <ControlPanel className="map__control-panel"/>
        </div>
    );
}
