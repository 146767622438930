import React, {forwardRef} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import {Box, Button, Drawer, Hidden, List, ListItem,} from '@material-ui/core';
import {NavLink as RouterLink} from 'react-router-dom';
import DrawerBg from '../../assets/Images/drawerBackground.svg'

const CustomRouterLink = forwardRef((props, ref) => {

    let target = null
    let p = Object.assign({}, props)
    if (p.to && p.to.startsWith('http')) {
        p.to = {pathname: props.to}
        target = '_blank'
    }

    return (
        <div
            ref={ref}
            style={{flexGrow: 1}}>
            <RouterLink {...p} target={target}/>
        </div>
    )
});
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.sideBar,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        padding: 0,
        backgroundImage: `url(${DrawerBg})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'bottom right'
    },
    drawer: {
        width: '100vw',
        backgroundColor: theme.palette.sideBar,
        marginTop: theme.height.topBar + theme.height.topBarBorder,
        [theme.breakpoints.up('md')]: {
            width: '33vw'
        },
        [theme.breakpoints.up('lg')]: {
            width: theme.width.drawer,
            height: `calc(100% - ${theme.height.topBar + theme.height.topBarBorder}px)`
        }
    },
    topbarMobile: {
        position: 'relative'
    },
    divider: {
        margin: theme.spacing(2, 0)
    },
    nav: {
        marginBottom: theme.spacing(2)
    },
    item: {
        paddingTop: 0,
        paddingBottom: 0
    },
    menuItem: {
        display: 'flex',
        justifyContent: 'start',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '17px',
        '&:hover': {
            backgroundColor: '#283848'
        },
        [theme.breakpoints.up('lg')]: {
            textAlign: 'center',
            display: 'block',
            fontSize: '12px',
            fontWeight: 400,
            lineHeight: '14px',
        }
    },
    active: {
        backgroundColor: '#283848'
    },
    icon: {
        display: 'inline-block',
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('lg')]: {
            display: 'block',
            marginRight: 0
        }
    }
}));

const SideBar = props => {
    const classes = useStyles();

    return (
        <Drawer
            className={classes.root}
            anchor="left"
            classes={{paper: classes.drawer}}
            onClose={props.onClose}
            open={props.open}
            pages={props.pages}
            variant={props.variant}
        >
            <div className={clsx(classes.root, props.drawerclassName)}>
                <Hidden lgUp={true}>
                    <Box className={classes.topbarMobile}>
                        {props.serviceMenu}
                        {props.workloadMenu}
                    </Box>
                </Hidden>
                <List className={classes.root}>
                    {props.pages.map(nav => (
                        <ListItem
                            className={classes.item}
                            disableGutters
                            key={nav.name}>
                            <Button
                                activeClassName={classes.active}
                                className={classes.menuItem}
                                component={CustomRouterLink}
                                to={nav.path}>
                                <div className={classes.icon}>{nav.icon}</div>
                                {nav.name}
                            </Button>
                        </ListItem>
                    ))}
                </List>
                <Hidden lgUp={true}>
                    <Box className={classes.userMenu}>
                        {props.userMenu}
                        {props.helpMenu}
                    </Box>
                </Hidden>
            </div>
        </Drawer>
    );
};

SideBar.propTypes = {
    className: PropTypes.string,
    onClose: PropTypes.func,
    open: PropTypes.bool,
    variant: PropTypes.string,
    pages: PropTypes.arrayOf(Object).isRequired,
    userMenu: PropTypes.node,
    serviceMenu: PropTypes.node,
    workloadMenu: PropTypes.node,
    helpMenu: PropTypes.node
};
export default SideBar;
