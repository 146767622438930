import React, {useEffect} from 'react';
import {useRouteMatch} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {loadWellsDataAction} from '../../store/cross-section';
import {AppState} from '../../store';
import {CrossSectionPage} from './CrossSectionPage';

export interface RouteInfo {
    wells: string;
}

export function ConnectedCrossSectionPage() {
    const isDataLoading = useSelector((state: AppState) => state.crossSection.isDataLoading);
    const loadError = useSelector((state: AppState) => state.crossSection.loadError);
    const formations = useSelector((state: AppState) => state.crossSection.formations);
    const wellMarkers = useSelector((state: AppState) => state.crossSection.wellMarkers);
    const dispatch = useDispatch();
    const match: any = useRouteMatch();
    const wellIdsStr = match.params.wells || '';

    useEffect(() => {
        // gather well ids from a url param
        dispatch(loadWellsDataAction(decodeURIComponent(wellIdsStr).split(',')));
    }, [wellIdsStr, dispatch]);

    return (
        <CrossSectionPage
            isDataLoading={isDataLoading}
            formations={formations}
            wellMarkers={wellMarkers}
            loadError={loadError}
        />
    );
}
