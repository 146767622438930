import React from "react";
import {SampleAppMainPage} from "./pages/main";
import ImageSearchIcon from "@material-ui/icons/ImageSearch";
import {SampleAppMapPage} from "./pages/map";
import {SampleAppCrossSectionPage} from "./pages/cross-section";


export default [
    {
        name: 'Visualization App',
        path: '/OSDUR2VisualizationApp',
        component: () => (<SampleAppMainPage/>),
        addToSideMenu: true,
        wrappedWithNavigation: true,
        exact: true,
        icon: <ImageSearchIcon/>
    }, {
        name: 'OSDU R2 Visualization App Map',
        path: '/map',
        component: () => (<SampleAppMapPage/>),
        wrappedWithNavigation: true,
        exact: false
    }, {
        name: 'OSDU R2 Visualization App Cross Section',
        path: '/cross-section/:wells',
        component: () => (<SampleAppCrossSectionPage/>),
        wrappedWithNavigation: true,
        exact: false
    },
]