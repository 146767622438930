import './index.css';
import theme from "./style/theme";
import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import * as serviceWorker from './serviceWorker';
import store from './state';
import pages from "./pages";
import {CssBaseline, ThemeProvider} from "@material-ui/core";
import {withNavigation} from "./navigation";
import Amplify, {Auth} from "aws-amplify";
import {AuthState, onAuthUIStateChange} from "@aws-amplify/ui-components";
import {logIn, logOut} from "./state/auth";
import NotFound from "./pages/NotFound";

Amplify.configure({
    Auth: {
        region: process.env.REACT_APP_REGION,
        userPoolId: process.env.REACT_APP_USER_POOLS_ID,
        userPoolWebClientId: process.env.REACT_APP_USER_POOLS_WEB_CLIENT_ID,
    },
    API: {
        endpoints: [
            {
                name: "api",
                endpoint: process.env.REACT_APP_BASE_API_URL,
                region: process.env.REACT_APP_REGION,
                custom_header: async () => {
                    const session = await Auth.currentSession()
                    const idTokenExpire = session.getIdToken().getExpiration();
                    const currentTimeSeconds = Math.round(+new Date() / 1000);
                    const willExpireInSeconds = idTokenExpire - currentTimeSeconds;
                    if (willExpireInSeconds < 100) {
                        console.log('refreshing user session')
                        const user = await Auth.currentAuthenticatedUser()
                        user.refreshSession(session.getRefreshToken(), (err, data) => {
                            if (err) {
                                store.dispatch(logOut());
                                return {}
                            } else {
                                console.log('refreshed user session')
                                return {Authorization: `Bearer ${data.getAccessToken().getJwtToken()}`}
                            }
                        });
                    }
                    return {Authorization: `Bearer ${session.getAccessToken().getJwtToken()}`}
                }
            }
        ]
    }
});

Auth.currentAuthenticatedUser()
    .then((data) => {
        store.dispatch(logIn(data));
    })
    .catch(() => {
        store.dispatch(logOut());
    })

onAuthUIStateChange((state, data) => {
    switch (state) {
        case AuthState.SignedIn: {
            store.dispatch(logIn(data));
            break;
        }
        default:

    }
})

const NotFoundWithNavigation = withNavigation(NotFound)

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <BrowserRouter>
                    <CssBaseline/>
                    <Switch>
                        <Redirect exact from='/' to='/Home'/>
                        {pages.map((page, i) => {
                            let Page = page.component
                            if (page.wrappedWithNavigation) {
                                Page = withNavigation(page.component)
                            }
                            return (
                                <Route path={page.path} exact={page.exact} key={i}>
                                    <Page/>
                                </Route>
                            );
                        })}
                        <Route><NotFoundWithNavigation/></Route>
                    </Switch>
                </BrowserRouter>
            </ThemeProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
