import React, {useState} from 'react';
import clsx from 'clsx';
import Proptypes from 'prop-types';
import {Button, Snackbar, SnackbarContent} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {Dialog, MessageBox} from '../../UI';

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: theme.width.snackbar,
        '& > * + *': {
            marginTop: theme.spacing(2)
        },
    },
    button: {
        '&:hover': {
            background: 'transparent',
            color: 'rgba(255,255,255,0.9)'
        },
        '&:focused': {
            background: 'transparent',
            color: 'rgba(255,255,255,0.9)'
        }
    },
    error: {
        '& .MuiSnackbarContent-root': {
            background: theme.palette.error.main,
        }
    }
}));

const SnackbarMoreInfo = ({
                              variant = 'simple', status = '',
                              message = '', description = '', content = '', open = false, handleSnackBarClose, ...rest
                          }) => {

    const classes = useStyles();
    const [dialogOpen, setDialogOpen] = useState(false);


    const handleMoreInfo = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setDialogOpen(true);
    }
    const closeDialog = () => {
        setDialogOpen(false);
    }
    return (
        <div>
            {
                variant === 'simple' ?
                    <Snackbar
                        className={status === 'error' ? clsx(classes.root, classes.error) : classes.root}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        open={open}
                        autoHideDuration={6000}
                        onClose={handleSnackBarClose}
                        {...rest}>
                        <SnackbarContent
                            message={message}
                            action={
                                <Button color='inherit'
                                        className={classes.button}
                                        variant='text'
                                        children='Dismiss'
                                        onClick={handleSnackBarClose}
                                />
                            }
                        />
                    </Snackbar>
                    :
                    <Snackbar
                        className={status === 'error' ? clsx(classes.root, classes.error) : classes.root}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        open={open}
                        autoHideDuration={6000}
                        onClose={handleSnackBarClose}
                        {...rest}>
                        <SnackbarContent
                            message={message}
                            action={
                                <>
                                    <Button color='inherit'
                                            className={classes.button}
                                            variant='text'
                                            children='More info'
                                            onClick={handleMoreInfo}
                                    />
                                    <Button color='inherit'
                                            className={classes.button}
                                            variant='text'
                                            children='Dismiss'
                                            onClick={handleSnackBarClose}
                                    />
                                </>
                            }
                        />
                    </Snackbar>
            }
            <Dialog open={dialogOpen}
                    closeDialog={closeDialog}
                    children={
                        <MessageBox
                            title='Error'
                            description={description}
                            content={content}
                        />
                    }
            />
        </div>
    );
}
export default SnackbarMoreInfo;
SnackbarMoreInfo.propTypes = {
    message: Proptypes.string.isRequired,
    open: Proptypes.bool.isRequired
}