import React from 'react';
import {Box, Typography, Link, Grid} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme =>(
  {
    container: {
      width:'100%',
      height:'100%',
      display:'flex',
      alignItems:'center',
      flexDirection: 'column',
      justifyContent: 'space-around',
      paddingTop: '10vh'    
   },
   content: {
      flex:1,
      textAlign: 'center'
   },
   heading : { 
      marginBottom: theme.spacing(3)
   },     
   detail : {
    marginBottom: theme.spacing(2.5),
    marginTop: theme.spacing(2.5)
   },
   link : {
     display: 'block',
     marginBottom: theme.spacing(3)
   }
  }
))

const ErrorBox = (props) => {
  const classes = useStyles();
  return(
    <Box className = {classes.container}>
      <Box className = {classes.content}>
        <Typography 
              className= {classes.heading}
              variant = 'h2' 
              component = 'h1' 
              children = 'Error'/>
          <Typography 
              className = {classes.description}
              variant = 'h5' 
              component = 'h3' 
              children = {props.description}/>      
          <Typography 
              className = {classes.detail}
              variant = 'body1' 
              component = 'p'
              children = {props.detail}
              >      
            </Typography>   
             <Link children = 'Contact us'
                    className = {classes.link}
                    color = 'primary'
                    underline = 'none'  
                    variant = 'body1'                 
              />    
             <Typography 
              variant = 'body1' 
              className = {classes.detail}
              component = 'p' children ='Want to go to another page instead?'/> 
              <Grid container>
                <Grid item sm={6} >
                    <Link children = 'Environment'
                        className = {classes.link}
                        color = 'primary'
                        underline = 'none'  
                        variant = 'body1'  
                        to               
                     /> 
                    <Link children = 'Footprint'
                          className = {classes.link}
                          color = 'primary'
                          underline = 'none'  
                          variant = 'body1'                 
                    /> 
                </Grid>
                <Grid item sm={6} >
                    <Link children = 'App Hub'
                        className = {classes.link}
                        color = 'primary'
                        underline = 'none'  
                        variant = 'body1'                 
                      /> 
                     <Link children = 'Accounts'
                          className = {classes.link}
                          color = 'primary'
                          underline = 'none'  
                          variant = 'body1'                 
                      /> 
                </Grid>
              </Grid>
      </Box>            
    </Box>
  )
}
export default ErrorBox;