import React, {useEffect, useState} from 'react';
import {API} from 'aws-amplify';
import TextField from '@material-ui/core/TextField';
import useDebounce from '../../hooks/useDebounce';
import Grid from "@material-ui/core/Grid";
import ReactJson from 'react-json-view'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TablePaginationActions from "@material-ui/core/TablePagination/TablePaginationActions";
import Alert from "@material-ui/lab/Alert";
import LinearProgress from "@material-ui/core/LinearProgress";
import Snackbar from "@material-ui/core/Snackbar";

const initialPagination = {
    count: 0,
    page: 0,
    rowsPerPage: 10
}

const initialKind = '*:*:*:*'
const initialDataPartition = 'opendes'

function Search() {

    const [error, setError] = useState('')
    const [errorSeverity, setErrorSeverity] = useState('error')

    const [kindValue, setKindValue] = React.useState(initialKind);
    const debouncedKindValue = useDebounce(kindValue, 500);

    const [dataPartitionValue, setDataPartitionValue] = React.useState(initialDataPartition);
    const debounceDataPartition = useDebounce(dataPartitionValue, 500);

    const [queryValue, setQueryValue] = React.useState('');
    const [queryResults, setQueryResults] = useState([])
    const [queryResultsLoader, setQueryResultsLoader] = useState(false)
    const [pagination, setPagination] = useState(initialPagination)
    const debouncedQueryValue = useDebounce(queryValue, 500);

    const handleError = (e) => {
        if (e.response) {
            console.log(e.response)
            if (e.response.status === 400) {
                setErrorSeverity('warning')
                setError(e.response.data.message)
            } else {
                setErrorSeverity('error')
                setError(e.response.statusText + ', for endpoint ' + e.response.config.url)
            }
        }
    }

    const fetchQueryResults = (p) => {
        setQueryResultsLoader(true)
        const body = {
            'kind': debouncedKindValue,
            'query': debouncedQueryValue,
            'limit': p.rowsPerPage,
            'offset': p.page * p.rowsPerPage
        }
        API.post('api', '/api/search/v2/query', {
            headers: {
                'data-partition-id': debounceDataPartition
            },
            body: body
        }).then(r => {
            setQueryResults(r.results)
            setPagination({
                count: r.totalCount,
                page: p.page,
                rowsPerPage: p.rowsPerPage
            })
        }).catch(handleError).finally(() => {
            setQueryResultsLoader(false)
        })

    }

    useEffect(() => {
            setPagination(initialPagination)
            fetchQueryResults(pagination)
        },
        // eslint-disable-next-line
        [debouncedKindValue, debounceDataPartition, debouncedQueryValue])

    const QueryResultsTable = () => {
        if (queryResults.length) {
            return (<Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Results</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        queryResults.map((r, i) => {
                            return (<TableRow key={i}>
                                <TableCell component="th" scope="row">
                                    <ReactJson src={r} name={r.id} collapsed={0}/>
                                </TableCell>
                            </TableRow>)
                        })
                    }

                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[10, 15, 20, 50]}
                            count={pagination.count}
                            rowsPerPage={pagination.rowsPerPage}
                            page={pagination.page}
                            SelectProps={{
                                inputProps: {'aria-label': 'rows per page'},
                                native: true,
                            }}
                            onChangePage={(event, newPage) => {
                                fetchQueryResults({
                                    count: pagination.count,
                                    page: newPage,
                                    rowsPerPage: pagination.rowsPerPage
                                })
                            }}
                            onChangeRowsPerPage={(event) => {
                                fetchQueryResults({
                                    count: pagination.count,
                                    page: pagination.page,
                                    rowsPerPage: parseInt(event.target.value)
                                })
                            }}
                            ActionsComponent={TablePaginationActions}
                        />
                    </TableRow>
                </TableFooter>
            </Table>)
        } else {
            return (<div></div>)
        }
    }

    return (
        <Grid container component={Paper} spacing={3}>
            <Grid item xs={12} md={12} lg={2}>
                <TextField style={{width: '100%'}} defaultValue={initialDataPartition} onChange={event => {
                    setDataPartitionValue(event.target.value);
                }} label='Data partition' />
            </Grid>
            <Grid item xs={12} md={12} lg={2}>
                <TextField style={{width: '100%'}} defaultValue={initialKind} onChange={event => {
                    setKindValue(event.target.value);
                }} label='Kind' />
            </Grid>
            <Grid item xs={12} md={12} lg={8}>
                <TextField style={{width: '100%'}} onChange={event => {
                    setQueryValue(event.target.value);
                }} label='Query' />
            </Grid>
            <Grid item lg={12}>
                {queryResultsLoader && <LinearProgress/>}
                <QueryResultsTable></QueryResultsTable>
            </Grid>
            <Snackbar open={!!error} autoHideDuration={3000} onClose={() => {
                setError(null)
            }}>
                <Alert severity={errorSeverity} onClose={() => {
                    setError(null)
                }}>
                    {error}
                </Alert>
            </Snackbar>
        </Grid>
    )
}

export default Search;