import Rig from "../../style/assets/Images/rig-2251648.png";
import B from "../../style/assets/Images/b.jpg";
import SolarPanel from "../../style/assets/Images/Home_Intro.png";
import Windmill from "../../style/assets/Images/Industry_Energy_Intro.png";
import A from "../../style/assets/Images/a.jpg";
import React from "react";
import Cards from "../../components/cards/Cards";
import SvgMenuHome from "../../style/assets/Icons/MenuHome";
import {Link} from "react-router-dom";


const ITEMS = [
    {
        title: "Explore Current Implementation",
        description:
            <>
                Each organization will want to explore what OSDU™ 
                <Link className="text-link" to="/Services"> Services </Link>
                and
                <Link className="text-link" to="/OSDUTestDataSet"> data </Link>
                are provided out-of-the-box. 47Lining has also deployed the Forum supplied tutorial
                <Link className="text-link" to="/OSDUR2VisualizationApp"> sample application </Link>
                for you to interact with and explore the code. 47Lining has also created a User Interface
                <Link className="text-link" to="/OSDUR2ManagementConsole"> console application </Link>
                that can help you navigate and explain key concepts of the OSDU Data Platform and leveraging it in
				a production operations environment. Finally, there is an explanation of how this environment is built on top of
                <Link className="text-link" to="/AWSIntegration"> AWS </Link>
                and what services are used behind the OSDU Data Platform.
            </>
        ,
        image: <img src={Rig} alt='x'/>,
        path: '/ExploreCurrentImplementation'
    }, {
        title: "Meet Future Needs",
        description:
            <>
                Once operators have fully explored the OSDU™ Data Platform capabilities delivered out-of-the-box,
				they will want to adapt the platform to their requirements. In this environment, operators can start by
                <Link className="text-link" to="/UserSetupAndIntegration"> adding new users </Link>
                and change security to meet corporate requirements. They can also load their own data using
                the APIs. Once user and data are loaded, organizations can better evaluate the OSDU Data Platform's
				ability to <Link className="text-link" to="/CustomerUseCases"> support their use cases. </Link>
                Should your organization need assistance in adapting the OSDU Data Platform to your requirements,
				47Lining can offer professional services engagements to assist.
            </>
        ,
        image: < img src={B} alt="x"/>,
        path: '/MeetFutureNeeds'
    }, {
        title: "Production Operations",
        description:
            <>
                Once operators have seen how the OSDU™ Data Platform can be adapted to their data and use cases, they
				will need to understand what it might take to <Link className="text-link" to="/DeploymentAndMaintenance"> deploy and maintain the OSDU Data Platform</Link>
                in an operational setting. This will involve integration with
                <Link className="text-link" to="/EnterpriseAppIntegrations"> enterprise applications, </Link>
                <Link className="text-link" to="/ScalabilityAndPerformance"> scalability and performance, </Link>
                <Link className="text-link" to="/FinancialImplications"> financial evaluation </Link>
                and
                <Link className="text-link" to="/OperateInProduction"> operating the OSDU Data Platform in production. </Link>
            </>
        ,
        image: <img src={SolarPanel} alt="x"/>,
        path: '/OSDUinProduction'
    },
    {
        title: "Management Console",
        description:
            <>
                47Lining has provided the Search and Load pages for the users convenience. These pages are meant to be a
                lightweight way to start interacting with the data directly using the APIs without code.
            </>
        ,
        image: < img src={Windmill} alt="x"/>,
        path: '/OSDUR2ManagementConsole'
    },
    {
        title: "Sample Application",
        description:
            <>
                The OSDU™ Forum Sample Visualization Application has been deployed for your organization to start
                exploring the possibilities that the OSDU Data Platform can offer you. The application uses the
				OSDU Data Platform APIs to visualize wells in a 3D space as well as on a geographic map.
            </>
        ,
        image: <img src={A} alt="x"/>,
        path: '/OSDUR2VisualizationApp'
    },
]

export default [
    {
        name: 'Home',
        path: '/Home',
        component: () => (<Cards items={ITEMS}/>),
        addToSideMenu: true,
        wrappedWithNavigation: true,
        exact: true,
        icon: <SvgMenuHome/>
    },
    ...ITEMS.filter(i => i.component).map(i => {
        return {
            path: i.path,
            component: i.component,
            wrappedWithNavigation: true,
            exact: true
        }
    })
]