import { createMuiTheme } from '@material-ui/core';
import palette from './palette';
import typography from './typography';
import overrides from './overrides/index';
const theme = createMuiTheme({
  palette,
  typography,
  overrides,
  zIndex: {
    appBar: 1100,
    drawer: 1200,
    popper: 1300
  },
  height: {
    topBar: 100,
    topBarBorder: 5
  },
  width: {
    drawer: 200,
    snackbar: 320
  }
});

export default theme;
