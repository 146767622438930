import {Card, CardContent} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React from "react";

export default function CustomerDataIngestion() {

    const base = process.env.REACT_APP_BASE_API_URL_DISPLAY;

    return (
        <div>
            <Card>
                <CardContent>
                    <Typography variant="h4">
                        Customer Data Ingestion
                    </Typography>
                    <br/>
                    <Typography variant="h5">
                        Data Loading
                    </Typography>
                    <br/>
                    <Typography component="div">
                        The OSDU™ Data Platform storage endpoint is a simple loading service. It does not provide for performing
                        data transformations as part of the upload. It is assumed that each organization has transformed
                        the appropriate data outside of the OSDU Data Platform and will be loading in the cleansed
                        result. R3 may introduce an ingestion framework inside of the OSDU Data Platform that will allow
                        raw data to be loaded and then enhanced inside of the platform.
                        <br/>
                        <br/>
                        If your organization would like assistance in transforming corporate data into OSDU format and
                        loading into OSDU, 47Lining can assist via a professional services engagement.
                        <br/>
                        <br/>
                        <a className='text-link' rel="noopener noreferrer" target="_blank"
                           href={base + '/api/storage/v2/swagger'}>Storage API</a> and&nbsp;
                        <a className='text-link' rel="noopener noreferrer" target="_blank"
                           href="https://community.opengroup.org/osdu/platform/system/storage/-/blob/master/docs/tutorial/StorageService.md">
                            Storage Documentation</a>
                    </Typography>
                    <br/>
                    <br/>

                </CardContent>
            </Card>
        </div>
    )
}