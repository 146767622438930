import React from 'react';
import './styles.css';
import {Well} from '../../../store/map';

export interface SelectedWellsProps {
    className?: string;
    wells: Well[];
}

export const SelectedWells = ({wells, className = ''}: SelectedWellsProps) => {
    return (
        <div className={`selected-wells ${className}`}>
            <div className="selected-wells__header">Wells for<br/>cross-section</div>
            <ul className="selected-wells__list">
                {wells.map(w => (
                    <li key={w.resourceId}>{w.facilityName}</li>
                ))}
            </ul>
        </div>
    );
}