import {Card, CardContent} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React from "react";
import {makeStyles} from "@material-ui/styles";
import DeploymentAndMaintenanceImage from "./assets/deployment_and_maintenence.png";

const useStyles = makeStyles(theme => (
    {
        imageDiv: {
            marginTop: '20px',
            textAlign: 'center'
        },
        image: {
            width: '1000px'
        },
    }
));
export default function DeploymentAndMaintenance() {
    const classes = useStyles();

    return (
        <div>
            <Card>
                <CardContent>
                    <Typography variant="h4">
                        Deploy and Maintain OSDU™ Data Platform
                    </Typography>
                    <br/>
                    <Typography component="div">
                        A key aspect to evaluate the OSDU Data Platform is the activities required to deploy and
						maintain the platform. The OSDU Forum provides open source common code that itself it not a complete
                        implementation of the OSDU standard. It has Service Provider Interfaces(SPI) that must be
						implemented by a Cloud Provider in order to function properly within the Cloud Providers environment.
						Those Cloud Providers may provide an environment for evaluation but that environment does not provide
						all of the Operations that are needed to function in a production setting. Service Providers work with
                        Cloud Providers to offer SaaS applications that allow Customers to use the OSDU Data Platform in
                        a pay-per-usage model. Service Providers, such as 47lining, will also maintain the OSDU Services
                        making sure they stay up to date with incremental OSDU standard patches and releases, ensuring your
                        organization always has the most recent updates.
                        <div className={classes.imageDiv}>
                            <img className={classes.image} src={DeploymentAndMaintenanceImage} alt='x'/>
                        </div>
                    </Typography>
                    <br/>
                    <br/>

                    <Typography variant="h5">
                        Operating OSDU
                    </Typography>
                    <br/>
                    <Typography component="div">
                        Operating the OSDU Data Platform in multiple regions and multiple environments in a large enterprise
						is a complex task that has many moving parts. Many customers will want to focus on delivering
						business value via use cases vs operating the OSDU Data Platform. The OSDU Service Providers
						will provide these capabilities for its Customers via Platform-as-a-Service models.
                    </Typography>

                </CardContent>
            </Card>
        </div>
    )
}