import Rig from "../../style/assets/Images/rig-2251648.png";
import React from "react";
import SolarPanel from "../../style/assets/Images/Home_Intro.png";
import Windmill from "../../style/assets/Images/Industry_Energy_Intro.png";
import Cards from "../../components/cards/Cards";
import ApartmentIcon from "@material-ui/icons/Apartment";
import B from "../../style/assets/Images/b.jpg";
import A from "../../style/assets/Images/a.jpg";
import EnterpriseAppIntegration from "./EnterpriseAppIntegration";
import ScalabilityAndPerformance from "./ScalabilityAndPerformance";
import FinancialImplications from "./FinancialImplications";
import DeploymentAndMaintenance from "./DeploymentAndMaintenance";
import OperateInProduction from "./OperateInProduction";


const ITEMS = [
    {
        title: "Enterprise App Integrations",
        description:
            <>
                When an organization decides to rollout the OSDU™ Data Platform, it will be necessary to integrate with
				corporate standard applications and protocols. In many large organizations those enterprise applications
				are dictated and must be used regardless of software running on-premise or in the cloud via SaaS.
            </>
        ,
        image: <img src={Rig} alt='x'/>,
        path: '/EnterpriseAppIntegrations',
        component: EnterpriseAppIntegration
    }, {
        title: "Scalability and Performance",
        description:
            <>
                While planning to rollout the OSDU™ Data Platform in each organization, companies will need to ensure
				that the performance of the system can meet the needs of their use cases. That vetting should occur
				across the array of OSDU services used by each organization. The OSDU services that are likely to be
				stressed the most are:ingestion, search and delivery. Evaluating these services requires a strategy
				that is unique to each service.
            </>
        ,
        image: < img src={B} alt="x"/>,
        path: '/ScalabilityAndPerformance',
        component: ScalabilityAndPerformance
    }, {
        title: "Financial Implications",
        description:
            <>
                When companies are evaluating if the OSDU™ Data Platform is the right direction for their organization,
				they must assess the financial commitment of the various alternatives and ensure that their use cases
				deliver value over and above the budget allotted to the initiative.
            </>
        ,
        image: <img src={SolarPanel} alt="x"/>,
        path: '/FinancialImplications',
        component: FinancialImplications
    },
    {
        title: "Deployment and Maintenance",
        description:
            <>
                A key aspect to evaluate the OSDU™ Data Platform is the activities required of deploying and maintaining
				the OSDU Data Platform. The OSDU Forum provides open source common code that itself it not a complete
				implementation. It has Service Provider Interfaces(SPI) that must be implemented by a Cloud Provider in
				order to function properly within the Cloud Providers environment.
            </>
        ,
        image: < img src={Windmill} alt="x"/>,
        path: '/DeploymentAndMaintenance',
        component: DeploymentAndMaintenance
    },
    {
        title: "Operate in Production",
        description:
            <>
                One of the major components to evaluating the OSDU™ Data Platform is determining a model for how your
				organization will operate an OSDU Data Platform in production. There are a number of factors to consider
				that all present different cost, security, accessibility, speed, and support trade-offs.
            </>
        ,
        image: <img src={A} alt="x"/>,
        path: '/OperateInProduction',
        component: OperateInProduction
    }
]

export default [
    {
        name: 'In Production',
        path: '/OSDUInProduction',
        component: () => (<Cards items={ITEMS}/>),
        addToSideMenu: true,
        wrappedWithNavigation: true,
        exact: true,
        icon: <ApartmentIcon/>
    },
    ...ITEMS.filter(i => i.component).map(i => {
        return {
            path: i.path,
            component: i.component,
            wrappedWithNavigation: true,
            exact: true
        }
    })
]