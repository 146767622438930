import React from 'react';
import PropTypes from 'prop-types';
import {Button,Link,Card,Box,Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import NoDataIcon from '../../../assets/Icons/graphic_no-data-available.svg'; 

const useStyles = makeStyles(theme => ({
    container: {
        flex: 1,
        display: 'flex',
        flexDirection:'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: theme.spacing(4)        
    },
    card: {
        maxWidth: theme.spacing(35),
        textAlign:'center',
        background:'transparent' ,
        [theme.breakpoints.up('md')]: {
            maxWidth: theme.spacing(70),
        }  
    },
    title:{
        fontWeight: 700,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2)
    },    
    description: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    link : {
        display: 'block',
        marginTop: theme.spacing(3),
        fontWeight: theme.typography.fontWeightMedium
    }
}))
const NoData = ({title, description, buttonText, handleNoDataBtnClick, 
     link ='#', linkText='or view documentation', ...rest}) => {
    const classes = useStyles();
    const preventDefault = (event) => event.preventDefault();
    return(
        <Box className={classes.container}>
            <Card elevation={0} 
              className = {classes.card}>
                <Box className = {classes.icon}>         
                <img src={NoDataIcon} alt='no data icon'/>
                </Box>
                <Typography variant="h5" component="h2" 
                    className = {classes.title}
                    children =  {title}/>        
                <Typography variant="body1" component="p"
                    className = {classes.description} 
                    children =  {description} />
                <Button variant ='contained' 
                        color='primary' 
                        children={buttonText}
                        onClick = {handleNoDataBtnClick}
                />   
                <Link href={link} 
                        className = {classes.link}
                        onClick={preventDefault} 
                        color="primary"
                        children = {linkText}                      
                />
             </Card>
        </Box>       
    )
}

export default NoData;
NoData.propTypes = {
    handleNoDataBtnClick : PropTypes.func.isRequired,
    description: PropTypes.string,
    title: PropTypes.string,
    NoDataIcon : PropTypes.string,
    link: PropTypes.string,
    linkText: PropTypes.string
}