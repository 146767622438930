import {Card, CardContent} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React from "react";

export default function ScalabilityAndPerformance() {
    return (
        <div>
            <Card>
                <CardContent>
                    <Typography variant="h4">
                        Scalability and Performance
                    </Typography>
                    <br/>
                    <Typography component="div">
                        While planning to rollout the OSDU™ Data Platform in each organization, companies will need to
						ensure that the performance of the system can meet the needs of their use cases. That vetting
						should occur across the array of OSDU services used by each organization. The OSDU services that
						are likely to be stressed the most are: ingestion, search and delivery. Evaluating these services
						requires a strategy that is unique to each service. An important factor to consider is how dynamic the
                        OSDU services and their underlying technologies need to be. Will they be cloud based allowing
                        services to be scaled based on current demand? Will they be container based? Or will they be
                        on-prem? Knowing if each company is able to accurately predict OSDU Data Platform usage ahead of
						time, may very well influence the decision on where the OSDU services are deployed and using what
                        technologies.
                    </Typography>
                    <br/>
                    <br/>

                    <Typography variant="h5">
                        Data Ingestion
                    </Typography>
                    <br/>
                    <Typography component="div">
                        Ingestion of data into the system is dependent on the source systems, data formats, data size,
                        data volume and frequency. To ensure proper performance it is likely that the environment will
                        need to be dynamic and scale up and down to meet the fluctuating demands.
                    </Typography>
                    <br/>
                    <br/>

                    <Typography variant="h5">
                        Search
                    </Typography>
                    <br/>
                    <Typography component="div">
                        Searching for metadata in the OSDU Data Platform is often done in smaller volumes but search results
						can be very large and must be paginated. Variables that affect search performance include the amount
						of data to be searched, the amount of data returned and the columns returned to the user. In addition,
                        the overall activity on the system can affect the speed at which all of this runs. Organizations
                        will need to determine what requirements they have for these factors and ensure the solution
                        they use for the OSDU Data Platform is able to meet them while remaining dynamic to fluctuations
						in demand.
                    </Typography>
                    <br/>
                    <br/>

                    <Typography variant="h5">
                        Delivery
                    </Typography>
                    <br/>
                    <Typography component="div">
                        Delivering data from the OSDU Data Platform to other corporate applications can occur in small
						amounts for display on a web page or bulk extracts for data refresh. The differing patterns to
						satisfy each use case will drive different performance profiles for the underlying Data Platform.

                        Batch data deliveries will typically be large volumes and will need to scale up to handle the
                        demand on the system. Designing the OSDU structure to handle this increase in load while not
                        disrupting other users will be key to maintaining acceptable performance.

                        Delivery is also used to display Well Logs, Work Product Components and Domain Driven Management
                        Systems (DDMS). In this scenario, the load placed on the OSDU Data Platform is not a large as
						batch but many users doing delivery for applications at the same time can stress the systems
						if not designed properly.
                    </Typography>
                    <br/>
                    <br/>


                    <Typography variant="h5">
                        47Lining Implementation
                    </Typography>
                    <br/>
                    <Typography component="div">
                        The 47Lining Enterprise PaaS on AWS provides for maximum flexibility that can be dynamically
                        scaled based on current demand. This will allow each company the ability to set performance
                        baselines or pre-determined schedules to meet their needs.
                    </Typography>
                    <br/>
                    <br/>
                </CardContent>
            </Card>
        </div>
    )
}