import {createSlice} from '@reduxjs/toolkit';
import {Auth} from "aws-amplify";

const UNAUTHORIZED_PAGES = [
    '/login',
    '/r'
]

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        userLoggedIn: false,
        userData: null
    },
    reducers: {
        logIn: (state, action) => {
            state.userData = {username: action.payload.username}
            if (UNAUTHORIZED_PAGES.includes(window.location.pathname)) {
                window.location.pathname = '/'
            }
        },
        logOut: (state) => {
            state.userData = null;
            Auth.signOut().then(() => {
                if (!UNAUTHORIZED_PAGES.includes(window.location.pathname)) {
                    window.location.pathname = '/login'
                }
            })
        }
    }
});


export const selectUserData = state => {
    return state.auth.userData;
}
export const {logIn, logOut} = authSlice.actions;

export default authSlice.reducer;