import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  MenuItem, Grid, Box, ClickAwayListener, Typography,
  IconButton, useMediaQuery, useTheme, Hidden
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { MenuPopper } from '../../UI';

const useStyles = makeStyles(theme => ({
  menuContainer: {
    color: theme.palette.white,
    alignItems: 'center',
    minWidth: '200px',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.up('lg')]: {
      position: 'relative'     
    }
  },

  menuTitle: {
    fontSize: '14px',
    lineHeight: '19px',
    fontWeight: 700,
    alignSelf: 'center',
    marginLeft: theme.spacing(4),
    flex: 1,
    [theme.breakpoints.up('lg')]: {
      flexGrow: 0
    }
  },
  mobileMenuTitle: {
    color: theme.palette.white,
    paddingLeft: theme.spacing(4),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('lg')] : {
      color: '#30373E'
    }
  },
  menuItem: {
    color: theme.palette.white,
    paddingLeft: theme.spacing(6),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(4),
      color: '#30373E'
    }
  }

}));

const ServiceMenu = ({title ='Choose a service', 
    servicesMenu,handleServiceChange, ...rest}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  });
  const [anchorEl, setAnchorEl] = useState(null);  
  const handleClick = event => {
    if (isDesktop) {
      setAnchorEl(anchorEl ? null : event.currentTarget.closest('#service-menu'));
    } else {
      setAnchorEl(anchorEl ? null : document.getElementById('appBar'));
    }
  };  
  const handleClickAway = () => {
    setAnchorEl(null);
  };
  const handlePopperClose = () => {
    setAnchorEl(null);
  }
  return (
    <ClickAwayListener onClickAway={handleClickAway}>     
        <Grid container item  className={classes.menuContainer}
          id='service-menu'>
          <Grid item className={classes.menuTitle}>
            <Typography variant="body2"
              color='inherit'>{servicesMenu.title}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton aria-controls="service-menu"
              aria-haspopup="true"
              color='inherit'
              onClick={handleClick}>
              {isDesktop ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
            </IconButton>
          </Grid>
          <MenuPopper 
          anchorEl={anchorEl}
          handleClose={handlePopperClose}        
          children={
            <Box>
              <Hidden lgUp>
                <Typography variant='h6' component='h4'
                  className={classes.mobileMenuTitle}
                  children = {title}/>               
              </Hidden>
              {servicesMenu.options.map(option => {
                return (
                  <MenuItem
                    color='inherit'
                    className={classes.menuItem}                    
                    onClick={handleServiceChange}
                    dense key={option.name}
                    disabled={option.disabled}                  
                  >
                    {option.name}
                  </MenuItem>
                )
              })}
            </Box>
          }
        >
        </MenuPopper>
        </Grid >     
   
    </ClickAwayListener>
  );
}
export default ServiceMenu;
ServiceMenu.propTypes = {
  className: PropTypes.string,
  handleServiceChange: PropTypes.func.isRequired,
  servicesMenu: PropTypes.object.isRequired
}
