import { LatLng } from 'leaflet';
import { FetchWellsInPolygonResponse } from '../../api/map.api';
import { Well } from './reducer';

export const SET_INITIAL_WELL = 'SET_INITIAL_WELL';
export const TOGGLE_WELL_SELECTION = 'TOGGLE_WELL_SELECTION';
export const SET_USER_POSITION = 'SET_USER_POSITION';
export const FINISH_DRAW_MODE = 'FINISH_DRAW_MODE';
export const ADD_POINT = 'ADD_POINT';
export const CHANGE_POINT = 'CHANGE_POINT';
export const DELETE_POINT = 'DELETE_POINT';
export const MAKE_POINT_REAL = 'MAKE_POINT_REAL';
export const CLEAR_ALL_POINTS = 'CLEAR_ALL_POINTS';
export const FIND_WELLS_SUCCESS = 'FIND_WELLS_SUCCESS';
export const FIND_WELLS_FAIL = 'FIND_WELLS_FAIL';
export const FIND_WELLS_START = 'FIND_WELLS_START';
export const SET_MAP_ZOOM = 'SET_MAP_ZOOM';
export const SET_MAP_CENTER = 'SET_MAP_CENTER';
export const CLEAR_SEARCH_ERROR = 'CLEAR_SEARCH_ERROR';

interface SetInitialWellAction {
  type: typeof SET_INITIAL_WELL;
  payload?: Well;
}

interface ToggleWellSelectionAction {
  type: typeof TOGGLE_WELL_SELECTION;
  payload: string;
}

interface GetUserLocationAction {
  type: typeof SET_USER_POSITION;
  payload: LatLng;
}

interface SwitchDrawingModeAction {
  type: typeof FINISH_DRAW_MODE;
}

interface AddPointAction {
  type: typeof ADD_POINT;
  payload: LatLng;
}

interface ChangePointAction {
  type: typeof CHANGE_POINT;
  payload: {
    id: string;
    position: LatLng;
  };
}

interface DeletePointAction {
  type: typeof DELETE_POINT;
  payload: string;
}

interface MakePointReal {
  type: typeof MAKE_POINT_REAL;
  payload: string;
}

interface ClearAllPointsAction {
  type: typeof CLEAR_ALL_POINTS;
}

interface FindWellsSuccessAction {
  type: typeof FIND_WELLS_SUCCESS;
  payload: FetchWellsInPolygonResponse;
}

interface FindWellsErrorAction {
  type: typeof FIND_WELLS_FAIL;
  payload: Error;
}

interface FindWellsStartAction {
  type: typeof FIND_WELLS_START;
}

interface SetMapZoomAction {
  type: typeof SET_MAP_ZOOM;
  payload: number;
}

interface SetMapCenterAction {
  type: typeof SET_MAP_CENTER;
  payload: LatLng;
}

interface ClearSearchErrorAction {
  type: typeof CLEAR_SEARCH_ERROR;
}

export type MapActionTypes =
  | ToggleWellSelectionAction
  | SetInitialWellAction
  | GetUserLocationAction
  | SwitchDrawingModeAction
  | AddPointAction
  | ChangePointAction
  | DeletePointAction
  | MakePointReal
  | ClearAllPointsAction
  | FindWellsSuccessAction
  | FindWellsErrorAction
  | FindWellsStartAction
  | SetMapZoomAction
  | SetMapCenterAction
  | ClearSearchErrorAction;
