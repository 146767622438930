import {CrossSectionActionTypes, LOAD_WELLS_DATA_FAIL, LOAD_WELLS_DATA_START, LOAD_WELLS_DATA_SUCCESS,} from './types';

export interface CrossSectionState {
    /** a progress flag for a cross-section data fetching */
    isDataLoading: boolean;

    /** all possible formations across every fetched well */
    formations: Formation[];

    /** fetched wells with their respective formation depths */
    wellMarkers: WellMarkers[];

    /** an order to align wells in cross-section chart */
    wellsOrder: string[];

    /** an error, occured while cross-section data fetching */
    loadError?: Error;
}

const initialState: CrossSectionState = {
    isDataLoading: false,
    formations: [],
    wellMarkers: [],
    wellsOrder: [],
    loadError: undefined,
};

export type Formation = string;
export type MarkerDepth = number;

export interface WellMarkers {
    facilityName: string;

    /** those depths require formations list to match on */
    markerDepths: MarkerDepth[];
    formations: Formation[];
}

export const crossSectionReducer = (
    state: CrossSectionState = initialState,
    action: CrossSectionActionTypes
): CrossSectionState => {
    switch (action.type) {
        case LOAD_WELLS_DATA_START:
            return {
                ...state,
                isDataLoading: true,
                wellsOrder: action.payload,
                loadError: undefined,
            };
        case LOAD_WELLS_DATA_FAIL:
            return {
                ...state,
                isDataLoading: false,
                loadError: action.payload,
            };
        case LOAD_WELLS_DATA_SUCCESS:
            return {
                ...state,
                isDataLoading: false,
                formations: action.payload
                    .map((w: { formations: any; }) => w.formations)
                    .flat()
                    // an meaningfull order will be lost, so do not rely on it
                    .filter((f: any, i: any, self: string | any[]) => self.indexOf(f) === i),
                wellMarkers: action.payload
                    .slice()
                    .sort((w: { resource_id: string; }) => state.wellsOrder.indexOf(w.resource_id))
                    .map(
                        (w: { facility_name: any; marker_depths: any; formations: any; }): WellMarkers => {
                            return {
                                facilityName: w.facility_name,
                                markerDepths: w.marker_depths,
                                formations: w.formations,
                            };
                        }
                    ),
            };
        default:
            return state;
    }
};
