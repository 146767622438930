import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles(theme => ({
    table: {
        minWidth: 650
    },
    tableHead: {
        borderBottom: `2px solid ${theme.palette.grey1}`
    }
}));

function createData(workloads, column2, column3) {
    return {workloads, column2, column3};
}

const rows = [
    createData('OSDU R1', 159, 6.0),
    createData('Workload 2', 237, 9.0),
    createData('Workload 3', 262, 16.0),
];

export default function DetailTable(props) {
    const classes = useStyles();

    return (
        <TableContainer>
            <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead className={classes.tableHead}>
                    <TableRow>
                        {/* demo of passing selected ID to tab component */}
                        <TableCell>Workloads - {props.selectedId}</TableCell>
                        <TableCell align="right">Column 2</TableCell>
                        <TableCell align="right">Column 3</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map(row => (
                        <TableRow key={row.workloads}>
                            <TableCell component="th" scope="row">
                                {row.workloads}
                            </TableCell>
                            <TableCell align="right">{row.column2}</TableCell>
                            <TableCell align="right">{row.column3}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
