import React from 'react';
import './styles.css';
import {Formation, WellMarkers} from '../../../store/cross-section';
import {Legend} from '../legend/Legend';
import {chartColors, ChartDataItem, prepareChartData} from '../../../lib/cross-section';
import {Chart} from '../chart/Chart';

export interface CrosSectionChartProps {
    formations: Formation[];
    wellMarkers: WellMarkers[];
}

export function CrosSectionChart({formations, wellMarkers}: CrosSectionChartProps) {
    // This is the right place to create very specific data representation
    // for recharts consumption only
    const data: ChartDataItem[] = prepareChartData(wellMarkers);

    // As each well represented by a trio in the data array
    // lets point clearly where is the middle, so we can draw wells
    const wellsToDrill: number[] = wellMarkers.map((w, i) => i * 3 + 1);

    // Lets align Legend to the right side of the chart.
    // It's not possible by recharts, so I shall do it manualy
    return (
        <div className="cross-section-chart__container">
            <div className="cross-section-chart__chart">
                <Chart
                    data={data}
                    colors={chartColors}
                    formations={formations}
                    wellsToDrill={wellsToDrill}
                />
            </div>
            <div className="chart-container-legend">
                <Legend formations={formations} colors={chartColors}/>
            </div>
        </div>
    );
}
