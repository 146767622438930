import { default as pallete } from '../palette';
export default {
 root : {
     backgroundColor: pallete.textField,     
     
 },
 underline: {
     '&:before': {
        borderBottomColor: 'rgba(0,0,0,0)'
     },
     "&$disabled": {
        "&:before": {
          "borderStyle": "none"
        }
      },
      '&:hover':{
        '&:before': {
            borderBottomColor: pallete.primary.main
         },
      }
 } 

}