import React from "react";
import {AmplifyAuthenticator, AmplifyContainer, AmplifyForgotPassword} from "@aws-amplify/ui-react";
import {useLocation} from "react-router-dom";
import {AuthState, Translations} from "@aws-amplify/ui-components";
import {I18n} from "aws-amplify";
import {Footer} from "../style/components";

function Reset() {

    // http://localhost:3000/r?e=YzNsc2QyVnpkR1Z5TG1ONmJXbHNRR2hwZEdGamFHbDJZVzUwWVhKaExtTnZiUT09

    const location = useLocation();

    const decode = (b64 = new URLSearchParams(location.search).get('e'), rounds = 2) => {
        try {
            let x = b64
            for (let i = 0; i < rounds; i++) {
                x = atob(x)
            }
            return x
        } catch (e) {
            return ''
        }
    }
    const email = decode()


    // hack back to sign in
    I18n.putVocabulariesForLanguage("en-US", {
        [Translations.BACK_TO_SIGN_IN]: " ",
    });

    return (
        <div>
            <AmplifyContainer>
                <AmplifyAuthenticator usernameAlias={'email'} initialAuthState={AuthState.ForgotPassword}>
                    <AmplifyForgotPassword slot="forgot-password" usernameAlias={'email'}
                                           headerText="Set password"
                                           formFields={[
                                               {
                                                   type: 'email',
                                                   label: 'Email Address',
                                                   required: true,
                                                   disabled: true,
                                                   value: email,
                                                   inputProps: {
                                                       ref: (r) => {
                                                           r.dispatchEvent(new Event("input"))
                                                       }
                                                   }
                                               }
                                           ]}
                    >
                        <div slot="secondary-footer-content"></div>
                    </AmplifyForgotPassword>
                </AmplifyAuthenticator>
            </AmplifyContainer>
            <Footer/>
        </div>

    );
}

export default Reset;