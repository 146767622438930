import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme =>(
    {
        cardContainer: {
            padding: theme.spacing(2),
            background: 'transparent'
        },
        imageContainer :{
            paddingRight: theme.spacing(3),
            '& img':{
                maxWidth:'100%'
            }
        },
        title :{
            fontWeight: 700
        }    
    }
));

const FlatCard = (props) => {
    const classes = useStyles();
    return (
        <Card elevation = {0}
         className = {classes.cardContainer}>
            <CardContent>
                <Grid container spacing = {0}>
                    <Grid item xs={5} sm={2}>
                        <Box className={classes.imageContainer} >
                            {props.image}
                        </Box>
                    </Grid>
                    <Grid item xs={7} sm={10}> 
                        <Box>
                            <Typography variant="h5" component="h2" 
                                className = {classes.title}                             
                                color='primary'>
                                    {props.title}
                            </Typography>
                            <Typography variant="body1" component="p">
                                {props.description}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

export default FlatCard;

FlatCard.propTypes = {
    title :PropTypes.string,
    description : PropTypes.string,
    image: PropTypes.string

}