import React from 'react';
import './styles.css';
import CircularProgress from '@material-ui/core/CircularProgress';

interface Props {
    // a font size. changes size of a componens as height and width are specified in "em"
    size?: number;
}

/**
 * super-duper animated bars will keep you amused
 * while little electrons rushes through cables under an ocean
 */
export function Loader({size = 24}: Props) {
    return (
        <div className="loader">
            <CircularProgress color="primary" size={size * 2} />
        </div>
    );
}
