import thunk from 'redux-thunk';
import authReducer from './auth';
import sharedReducer from './shared';
import {mapReducer} from '../pages/sample-app/store/map';
import {crossSectionReducer} from '../pages/sample-app/store/cross-section';
import {wellSearchReducer} from '../pages/sample-app/store/well-search';
import {trajectoryReducer} from '../pages/sample-app/store/trajectory';
import {applyMiddleware, combineReducers, compose, createStore} from 'redux';


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
    shared: sharedReducer,
    auth: authReducer,
    map: mapReducer,
    crossSection: crossSectionReducer,
    wellSearch: wellSearchReducer,
    trajectory: trajectoryReducer,
});

const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk)));

export default store;
