import {Card, CardContent} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React from "react";

export default function FinancialImplications() {
    return (
        <div>
            <Card>
                <CardContent>
                    <Typography variant="h4">
                        OSDU™ Financial Commitment vs Budget and Alternatives
                    </Typography>
                    <br/>
                    <Typography component="div">
                        When companies are evaluating if the OSDU Data Platform is the right direction for their
						organization, they must assess the financial commitment of the various alternatives and ensure
						that their use cases deliver value over and above the budget allotted to the initiative.
                    </Typography>
                    <br/>
                    <br/>

                    <Typography variant="h5">
                        DIY vs SaaS
                    </Typography>
                    <br/>
                    <Typography component="div">
                        When choosing to proceed with the OSDU Data Platform, several phases impact the cost of that
                        choice. There are a different set of activities for each organization if it were decided to
                        deploy their own Open Source OSDU standard. In that scenario, they would be responsible for
                        downloading the code from the appropriate cloud provider repository, creating deployment scripts
                        and operating the system in production including maintenance releases. Alternatively, Service
                        Providers such as 47Lining provide a hosted environment on a particular cloud provider. The
                        service provider will deploy the code, keep up with maintenance releases and operate the system
                        in production. This frees each company to focus on solving their use cases vs operating the OSDU
                        Data Platform. The cost structure of these service provider options can vary, but in the case of
                        47Lining, it is consumption based. Meaning charges are only applied for how much data is under
                        management and how often the OSDU services are used by each company.
                    </Typography>
                    <br/>
                    <br/>

                    <Typography variant="h5">
                        Use Case Onboarding to OSDU
                    </Typography>
                    <br/>
                    <Typography component="div">
                        In addition to the cost of deploying and operating the OSDU Data Platform, each organization
                        must evaluate the cost and process of deploying incremental use cases onto the platform. When
                        rolling your own deployment of the OSDU standard, each company will be responsible for 100% of
						the effort to deploy the uses cases. Service Providers can help in this effort by leveraging
						pre-built tools across the many customers they service. If the value of OSDU is to be realized,
						the cost of deploying incremental use cases to the platform must be easier and cost decreasing
						over time as experience is gained and applied.
                    </Typography>
                </CardContent>
            </Card>
        </div>
    )
}