import {default as palette} from '../palette';

export default { 
  root : {      
     backgroundColor: palette.white   
  },
  elevation1: {
    boxShadow: '0px 3px 4px rgba(0, 0, 0, 0.14)',    
  }

}