import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AppState} from '../../../store';
import {clearAllPointsAction, findWellsAction, MIN_WELLS_COUNT} from '../../../store/map';
import ControlPanel from './ControlPanel';

export function ControlPanelConnected({className}: { className?: string }) {
    const points = useSelector((state: AppState) => state.map.points);
    const isDrawingMode = useSelector((state: AppState) => state.map.isDrawingMode);
    const selectedWellIds = useSelector((state: AppState) => state.map.selectedWellIds);
    const isLoading = useSelector((state: AppState) => state.map.isLoading);
    const dispatch = useDispatch();

    const handlePolygonClear = () => {
        dispatch(clearAllPointsAction());
    };

    const handleSearchInPolygon = () => {
        dispatch(findWellsAction(points));
    };

    const isPolygonCleadDisabled = points.length === 0;
    const isSearchInPolygonDisabled = isDrawingMode;
    const isCrossSectionDisabled = selectedWellIds.length < MIN_WELLS_COUNT;

    return (
        <ControlPanel
            className={className}
            isPolygonClearDisabled={isPolygonCleadDisabled}
            isSearchInPolygonDisabled={isSearchInPolygonDisabled}
            isCrossSectionDisabled={isCrossSectionDisabled}
            isLoading={isLoading}
            crossSectionLink={`/cross-section/${selectedWellIds.map(encodeURIComponent).join(',')}`}
            onPolygonClear={handlePolygonClear}
            onSearchInPolygon={handleSearchInPolygon}
        />
    );
}

export default ControlPanel;
