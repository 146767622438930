import React from 'react';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {AutoComplete} from '../../UI'

const useCustomStyles = makeStyles((theme) => ({
    root: {
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        borderColor: theme.palette.white,
        color: theme.palette.white,
        '&:hover': {
            backgroundColor: 'transparent',
        },
        '&$focused': {
            backgroundColor: 'transparent',
            borderColor: theme.palette.white,
        },
        '& .MuiOutlinedInput-root': {
            borderColor: theme.palette.white,
            '&:focused': {
                borderColor: theme.palette.white
            },
            '&:hover': {
                borderColor: theme.palette.white
            },
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.white
            },
        },
        '& .MuiInputBase-input': {
            color: theme.palette.white,
            [theme.breakpoints.up('lg')]: {
                color: '#30373E'
            },
        },
        '& .MuiIconButton-root': {
            color: theme.palette.white,
            [theme.breakpoints.up('lg')]: {
                color: '#30373E'
            },
        },
        '& .MuiFormLabel-root.Mui-focused': {
            color: theme.palette.grey3,
            [theme.breakpoints.up('lg')]: {
                color: theme.palette.primary.main,
            }
        }
    },
    focused: {
        borderColor: theme.palette.white,
    },
}));

function CustomTextField(props) {
    const classes = useCustomStyles();
    return <TextField className={classes.root}
        InputProps={{ classes }} {...props} />;
}
const useStyles = makeStyles(theme => ({
    root: {
        marginBottom: theme.spacing(4),
        width: '100%',
        color: theme.palette.white,
        '&:last-of-type': {
            marginBottom: theme.spacing(3),
        }
    },
    TextFieldLabel: {
        color: theme.palette.grey3,
        '&$focused': {
            color: theme.palette.white
        },
        [theme.breakpoints.up('lg')]: {
            color: theme.palette.primary.main
        }
    }
}));
const MenuSelect = (props) => {
    const classes = useStyles();
    return (
        <AutoComplete
            label = {props.label}
            id={props.id}
            className={classes.root}
            options={props.options}
            getOptionLabel={(option) => option.name}
            defaultValue={props.defaultValue}
            closeIcon={null}
            fullWidth
            renderInput={(param) =>
                <CustomTextField {...param}
                    label={props.label}
                    variant={props.variant}
                    InputLabelProps={{ className: classes.TextFieldLabel }}
            />}
        />
    )
}
export default MenuSelect;
