import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {Typography, Grid, Button, IconButton, ClickAwayListener,
        useMediaQuery, useTheme, Hidden, Box} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { MenuPopper, MenuSelect } from '../../UI';

const useStyles = makeStyles(theme => ({ 
  gridItem: {
    paddingTop: `${theme.spacing(1)}px !important`,
    paddingBottom: `${theme.spacing(1)}px !important`,
    marginLeft: theme.spacing(4),
    alignContent: 'center',
    [theme.breakpoints.up('lg')]: {
      paddingBottom: `0px !important`,
      '&:first-child': {
        marginLeft: theme.spacing(1)
      }
    }
  }, 
  menuWrapper: {
    padding: theme.spacing(2)
  },  
  menuContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderBottom: '1px solid rgba(255,255,255, 0.55)',
    color: theme.palette.white,
    borderTop: `1px solid ${theme.palette.grey2}`,
    [theme.breakpoints.up('lg')]: {
      paddingTop: 0,
      paddingBottom: 0,
      borderTopWidth: 0,
      display: 'flex',
      position: 'relative'
    }
  },
  menuTitleWrapper: {
    flexDirection: 'column',
    width: '75%',
    flexBasis: '75%',
    maxWidth: '75%',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      width: 'auto',
      alignContent: 'center',
      alignItems: 'center',
      flexBasis: 'auto',
      maxWidth: '100%',
      flex: 1
    }
  },
  iconWrapper: {
    marginLeft: 0,
    width: '25%',
    flexBasis: '25%',
    maxWidth: '25%',
    justifyContent: 'flex-end',
    [theme.breakpoints.up('lg')]: {
      width: 'auto',
      flexBasis: 'auto',
      maxWidth: '100%',
    }
  },
  mobileMenuTitle: {
    color: theme.palette.white,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('lg')] : {
      color: '#30373E'
    }
  },
  label: {
    color: theme.palette.grey3,
    fontSize: '12px',
    lineHeight: '16px'
  },
  value: {
    color: theme.palette.white,
    fontSize: '14px',
    lineHeight: '19px',
    fontWeight: 600
  },
}));

const WorkloadMenu = (props) => {
  const theme = useTheme();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null); 
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  });
  const openMenu = (event) => {
    if (isDesktop) {
      setAnchorEl(anchorEl ? null : event.currentTarget.closest('#workload-menu'));     
    } else {
      setAnchorEl(anchorEl ? null : document.getElementById('appBar'));      
    }
  };
  const handlePopperClose = () => {
    setAnchorEl(null);
  }
  return (
    <>   
      <ClickAwayListener onClickAway={() => { setAnchorEl(null) }}>
        <Grid item container
          className={classes.menuContainer}
          id='workload-menu'>
          <Grid item container sm={9} md={12} 
                className={classes.menuTitleWrapper}>
            <Grid item className={classes.gridItem}>
              <Typography 
                className={classes.label}>{props.environments.title}
               </Typography>
              <Typography className={classes.value} 
                        variant='body2'>{props.environmentValue.name}
              </Typography>
            </Grid>
            <Grid item className={classes.gridItem}>
              <Typography className={classes.label}>
                  {props.regions.title}
               </Typography>
              <Typography className={classes.value} 
                  variant='body2'>{props.regionValue.name}
              </Typography>
            </Grid>
            <Grid item className={classes.gridItem}>
              <Typography className={classes.label}>{
                  props.workloads.title}
              </Typography>
              <Typography className={classes.value} 
                          variant='body2'>{props.workloadValue.name}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container sm={3} md={12} 
                className={clsx(classes.gridItem, classes.iconWrapper)}>
            <IconButton onClick={openMenu}
              color='inherit'>
              {isDesktop ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
            </IconButton>
          </Grid>
          <MenuPopper
            anchorEl={anchorEl}
            handleClose={handlePopperClose}          
            children={
              <Box className={classes.menuWrapper} >
                <Hidden lgUp>
                  <Typography variant='h6' component='h4'
                    className={classes.mobileMenuTitle}>
                    Choose a deployment
                  </Typography>
                </Hidden>
                <MenuSelect 
                  id="environment-select" 
                  options={props.environments.options}                
                  defaultValue={props.environmentValue}
                  label='Environment'
                  variant={isDesktop ? 'filled' : 'outlined'}
                  />  
                  <MenuSelect 
                   id="region-select"
                   options={props.regions.options}             
                   defaultValue={props.regionValue}
                   label='Region'
                   variant={isDesktop ? 'filled' : 'outlined'}
                  />              
                <MenuSelect 
                   id="workload-select"
                   options={props.workloads.options}          
                   defaultValue={props.workloadValue}
                   label='Workload'
                   variant={isDesktop ? 'filled' : 'outlined'}
                  />                
                <Button variant='contained'
                  color='primary'
                  onClick={props.handleWorkloadChange}>Go
                </Button>
              </Box>
            }>
          </MenuPopper>
        </Grid>
      </ClickAwayListener>
    </>
  );
}
export default WorkloadMenu;
WorkloadMenu.propTypes = {
  environments: PropTypes.object,
  environmentValue: PropTypes.object,
  regions: PropTypes.object,
  regionValue: PropTypes.object,
  workloads: PropTypes.object,
  workloadValue: PropTypes.object,
  HandleWorkloadChange: PropTypes.func
}