import React from "react";
import ImageCard from "../../style/components/UI/Card/ImageCard";
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {useHistory} from "react-router-dom";

const useStyles = makeStyles(theme => ({
    container: {
        width: '100%'
    },
    clickable: {
        cursor: 'pointer'
    },
    divider: {
        borderTop: `2px solid ${theme.palette.secondary.main}`,
        width: '100%'
    }
}));


function Cards(props) {
    const classes = useStyles();
    const history = useHistory();


    const onLinkClick = (i) => {
        if (i.path) {
            if (i.path.startsWith('http')) {
                const win = window.open(i.path, '_blank');
                win.focus();
            } else {
                history.push(i.path)
            }
        }
    }

    const clickClass = (i) => {
        if (i.path) {
            return classes.clickable
        } else {
            return {}
        }
    }

    return (
        <Grid container spacing={3}
              className={classes.container}
              alignItems="center">
            {props.items.map(i => {
                return (
                    <Grid item xs={12} md={12} lg={4}
                          key={i.title}>
                        <ImageCard
                            title={<div className={clickClass(i)} onClick={() => onLinkClick(i)}>{i.title}</div>}
                            description={i.description}
                            image={<div className={clickClass(i)} onClick={() => onLinkClick(i)}> {i.image}</div>}
                            hideDivider={i.hideDivider}
                        />
                    </Grid>
                )
            })}
        </Grid>
    )
}

export default Cards;