import Rig from "../../style/assets/Images/rig-2251648.png";
import React from "react";
import SolarPanel from "../../style/assets/Images/Home_Intro.png";
import Windmill from "../../style/assets/Images/Industry_Energy_Intro.png";
import Cards from "../../components/cards/Cards";
import AssignmentIcon from '@material-ui/icons/Assignment';
import UserSetupAndIntegration from "./UserSetupAndIntegration";
import CustomerDataIngestion from "./CustomerDataIngestion";
import CustomerUseCases from "./CustomerUseCases";

const ITEMS = [
    {
        title: "User Setup and Integration",
        description:
            <>
                User management is conducted via the AWS Cognito instance in each Customer’s AWS Account. The current
                steps for adding new users is a manual process. Thus to add/modify/delete users please submit a support
                ticket to 47Lining and a 47Lining support agent will make the change the Customer’s behalf.
            </>
        ,
        image: <img src={Windmill} alt='x'/>,
        path: '/UserSetupAndIntegration',
        component: UserSetupAndIntegration
    },
    {
        title: "Customer Data Ingestion",
        description:
            <>
                Customer data loading is achieved via a simple loading service. It does not provide for performing data
                transformations as part of the upload. It is assumed that each organization has previously transformed
				the appropriate data and will be loading in the cleansed result.
            </>
        ,
        image: < img src={Rig} alt="x"/>,
        path: '/CustomerDataIngestion',
        component: CustomerDataIngestion
    },
    {
        title: "Customer Use Cases",
        description:
            <>
                As a first step in your journey to OSDU™ Data Platform adoption, you can use the 47Lining Enterprise PaaS
				environment to explore its suitability to support your upstream workflows. You can also work with
				47Lining to collaborate on lead use cases to evaluate OSDU Data Platform capabilities to provide
				development workflows.
            </>
        ,
        image: <img src={SolarPanel} alt="x"/>,
        path: '/CustomerUseCases',
        component: CustomerUseCases
    },
]

export default [
    {
        name: 'Meet Future Needs',
        path: '/MeetFutureNeeds',
        component: () => (<Cards items={ITEMS}/>),
        addToSideMenu: true,
        wrappedWithNavigation: true,
        exact: true,
        icon: <AssignmentIcon/>
    },
    ...ITEMS.filter(i => i.component).map(i => {
        return {
            path: i.path,
            component: i.component,
            wrappedWithNavigation: true,
            exact: true
        }
    })
]