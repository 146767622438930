import React from 'react';
import Typography from "@material-ui/core/Typography";
import {Card, CardContent} from "@material-ui/core";


export default function Endpoints() {

    const base = process.env.REACT_APP_BASE_API_URL_DISPLAY;

    return (
        <div>
            <Card>
                <CardContent>
                    <Typography variant="h4">
                        Your OSDU™ Service Endpoints
                    </Typography>
                    <br/>

                    <Typography component="div">
                        The following information shows how you can access the OSDU service endpoints for this installation.
                        They are all based upon the Preview Deployment URL for the Management Console as your {base}.

                        Using the {base} you can build the following service endpoints.
                    </Typography>
                    <br/>
                    <br/>

                    <Typography variant="h5">
                        Storage
                    </Typography>
                    <br/>
                    <Typography component="div">
                        <a className="text-link" rel="noopener noreferrer" target="_blank"
                           href={`${base}/api/storage/v2/records`}>
                            {base}/api/storage/v2
                        </a>

                    </Typography>
                    <Typography component="div">
                        The Storage API supports records and other endpoints as defined{" "}
                        <a className="text-link" rel="noopener noreferrer" target="_blank"
                           href="https://community.opengroup.org/osdu/platform/system/storage/-/blob/master/docs/api/storage_openapi.yaml">
                            here
                        </a>
                        .

                    </Typography>
                    <br/>
                    <br/>

                    <Typography variant="h5">
                        Search
                    </Typography>
                    <br/>
                    <Typography component="div">
                        <a className="text-link" rel="noopener noreferrer" target="_blank"
                           href={`${base}/api/search/v2/query`}>
                            {base}/api/search/v2
                        </a>
                    </Typography>
                    <Typography component="div">
                        The Search API supports records and other endpoints as defined{" "}
                        <a className="text-link" rel="noopener noreferrer" target="_blank"
                           href="https://community.opengroup.org/osdu/platform/system/search-service/-/blob/master/docs/api/search_openapi.yaml">
                            here
                        </a>
                        .
                    </Typography>
                    <br/>
                    <br/>

                    <Typography variant="h5">
                        Delivery
                    </Typography>
                    <br/>
                    <Typography component="div">
                        <a className="text-link" rel="noopener noreferrer" target="_blank"
                           href={`${base}/api/delivery/v2/getFileSignedUrl`}>
                            {base}/api/delivery/v2
                        </a>
                    </Typography>
                    <Typography component="div">
                        The Delivery API supports records and other endpoints as defined{" "}
                        <a className="text-link" rel="noopener noreferrer" target="_blank"
                           href="https://community.opengroup.org/osdu/platform/system/delivery/-/blob/master/docs/api/delivery.yaml">
                            here
                        </a>
                        .
                    </Typography>
                    <br/>
                    <br/>

                    <Typography variant="h5">
                        Legal
                    </Typography>
                    <br/>
                    <Typography component="div">
                        <a className="text-link" rel="noopener noreferrer" target="_blank"
                           href={`${base}/api/legal/v1/legaltags`}>
                            {base}/api/legal/v1
                        </a>
                    </Typography>
                    <Typography component="div">
                        The Legal API supports records and other endpoints as defined{" "}
                        <a className="text-link" rel="noopener noreferrer" target="_blank"
                           href="https://community.opengroup.org/osdu/platform/security-and-compliance/legal/-/blob/master/docs/api/compliance_openapi.yaml">
                            here
                        </a>
                        .
                    </Typography>
                    <br/>
                    <br/>

                    <Typography variant="h5">
                        Entitlements
                    </Typography>
                    <br/>
                    <Typography component="div">
                        <a className="text-link" rel="noopener noreferrer" target="_blank"
                           href={`${base}/api/legal/entitlements/v1/groups`}>
                            {base}/api/entitlements/v1
                        </a>
                    </Typography>
                    <Typography component="div">
                        The Entitlements API supports records and other endpoints as defined{" "}
                        <a className="text-link" rel="noopener noreferrer" target="_blank"
                           href="https://community.opengroup.org/osdu/documentation/-/wikis/Releases/R2.0/Services/Entitlements/Swagger">
                            here
                        </a>
                        .
                    </Typography>
                    <br/>
                    <br/>
                </CardContent>
            </Card>
        </div>
    )
}