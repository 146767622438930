import React from "react";
import { default as pallete } from '../../theme/palette'

function SvgIconTableView75(props) { 
    
  const fillColor = (props.className === 'disabled') ? pallete.grey3 : pallete.primary.main ;
  
  return (
  <svg width={24} height={24} {...props}>
  <g id="icon_table-view-75" transform="translate(24 24) rotate(180)">
    <path id="Path_2200" data-name="Path 2200" d="M0,0H24V24H0Z" fill="none"/>
    <path id="Path_2201" data-name="Path 2201" d="M19,13.6v1.72H5V9.882H19M21,5H3V6.72H21Zm0,3.12H3v8.923H21Z" transform="translate(0 0.979)" 
    fill={fillColor}/>
  </g>
</svg>

  );
}

export default SvgIconTableView75;