import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { Button, MenuItem, Box, ClickAwayListener } from '@material-ui/core';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import Popper from '@material-ui/core/Popper';
import { Dialog } from './../../UI';

const useStyles = makeStyles(theme => ({
    popper: {
        backgroundColor: theme.palette.white,
        padding: theme.spacing(2),
        borderRadius: '2px',
        boxShadow: `0px 6px 10px rgba(0, 0, 0, 0.14)`,
        zIndex: theme.zIndex.popper
    },
    actionMenu: {
        marginTop: '2px',
        backgroundColor: theme.palette.white
    },
    actionMenuClose: {
        float: 'right',
        zIndex: theme.zIndex.popper
    }
}));

const TableActionWithDialog = ({dialogOpen, actionMenuOptions, onMenuClick,
        closeDialogHandler, ...rest}) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [menuOpen, setMenuOpen] = useState(false);
    const [actionMenuIndex, getMenuIndex] = useState(0);

    const handleClick = event => {
        anchorEl === null ? setAnchorEl(event.currentTarget) : setAnchorEl(null);
        setMenuOpen(!menuOpen);
    };
    const handleClickAway = () => {
        setAnchorEl(null);
        setMenuOpen(false);
    }

    const openActionMenu = (event) => {
        setAnchorEl(null);      
        onMenuClick();
        const index = _.findIndex(actionMenuOptions,
            (option) => option.value === event.currentTarget.getAttribute('value'));
        getMenuIndex(index);
    }  
       
    return (
        <>
            <ClickAwayListener onClickAway={handleClickAway}>
                <Box variant='div'>
                    <Button onClick={handleClick}
                        className = {classes.actionMenu}
                        id='table-action-menu'
                        variant='outlined' color='primary'
                        endIcon={menuOpen ? <ArrowDropUp /> : <ArrowDropDown />}
                        children='Action'>
                    </Button>
                    <Popper open={Boolean(anchorEl)}
                        className={classes.popper}
                        anchorEl={anchorEl}
                        transition placement='bottom-start' >
                        {actionMenuOptions.map(option =>
                            <MenuItem value={option.value}
                                key={option.value.replace(' ', '-')}
                                onClick={openActionMenu}>
                                {option.label}
                            </MenuItem>
                        )}
                    </Popper>
                </Box>
            </ClickAwayListener>
            <Dialog 
                closeDialog = {closeDialogHandler}
                open={dialogOpen}
                children={actionMenuOptions[actionMenuIndex].actionContent}
                {...rest}
            />
        </>
    );
}
export default TableActionWithDialog;
TableActionWithDialog.propTypes = {
    actionMenuOptions: PropTypes.array.isRequired,
    closeDialogHandler: PropTypes.func.isRequired,
    dialogOpen: PropTypes.bool.isRequired,
    onMenuClick: PropTypes.func.isRequired
}