import React from 'react';
import PropTypes from 'prop-types';
import {IconButton, Typography, Box} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import ArrowLeft from '@material-ui/icons/ArrowLeft';

const useStyles = makeStyles((theme) => ({
 root : {
     color: theme.palette.white,
     display: 'flex',
     justifyContent: 'space-between',
     alignContent: 'center',
     paddingLeft: theme.spacing(2),
     paddingRight: theme.spacing(2),    
     paddingTop: theme.spacing(1),
     paddingBottom: theme.spacing(1),
     borderBottom: `1px solid ${theme.palette.grey2}`
 },
 title: {
     color: theme.palette.white,
     alignSelf:'center',
     paddingLeft: theme.spacing(2),
     fontWeight: 700
 }
}));

const BackToMainMenu = (props) => {
    const classes = useStyles();
    return (
    <Box className = {classes.root}>
        <Typography variant='body2' component = 'p' 
                    className = {classes.title}>
            Main menu
        </Typography>
        <IconButton color = 'inherit'           
            onClick = {props.handleClick}>
         < ArrowLeft/>
      </IconButton> 
    </Box>  
    )
}
export default BackToMainMenu;
BackToMainMenu.propTypes = {
    handleClick: PropTypes.func.isRequired
}