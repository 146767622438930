import React, {HTMLAttributes, ReactNode} from 'react';
import './styles.css';

export interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
    children: ReactNode | ReactNode[];
    className?: string;
    disabled?: boolean;
}

/**
 * Styled button component
 * @param {ButtonProps} props
 */
export function Button({children, className = '', disabled, ...restProps}: ButtonProps) {
    return (
        <button className={`button ${className}`} disabled={disabled} {...restProps}>
            {children}
        </button>
    );
}
