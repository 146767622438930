import React from 'react';
import './styles.css';
import {Hint, Loader} from '../../components/shared';
import {CrosSectionChart} from '../../components/cross-section';
import {Formation, WellMarkers} from '../../store/cross-section';

export interface CrossSectionPageProps {
    /** a progress flag for a cross-section data fetching */
    isDataLoading: boolean;

    /** all possible formations across every fetched well */
    formations: Formation[];

    /** fetched wells with their respective formation depths */
    wellMarkers: WellMarkers[];

    /** an error, occured while data fetching, will be displayed as a page comntent */
    loadError?: Error;
}

const cannotLoadDataMsg = 'Cannot load data';

/**
 * Contains a cross-section report, represented by a Chart with a Legend
 */
export function CrossSectionPage({
                                     isDataLoading,
                                     formations,
                                     wellMarkers,
                                     loadError,
                                 }: CrossSectionPageProps) {
    return (
        <div className="cross-section-page">
            {loadError ? (
                <Hint title={cannotLoadDataMsg} subTitle={String(loadError)}/>
            ) : isDataLoading ? (
                <Loader/>
            ) : (
                <CrosSectionChart formations={formations} wellMarkers={wellMarkers}/>
            )}
        </div>
    );
}
