import React from 'react';
import {Typography, Link, Breadcrumbs, Box}  from '@material-ui/core';
import clsx from 'clsx';
import { Link as RouterLink, useLocation } from "react-router-dom";
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles(theme =>(
{
  root: {
    paddingBottom: theme.spacing(2)
  },
  breadCrumbs: {
    fontSize: 14,   
    color: '#173A60'   
  },
  active: {
    fontWeight: 600
  }

}));

export default function ConsoleBreadcrumbs(props) {
  const location = useLocation();
  const classes = useStyles();
  const currentLink = props.navLinks.find(link => link.path === location.pathname);  
  return ( 
    <Box variant ='div' className = {classes.root}>
      <Breadcrumbs aria-label="breadcrumb" className={classes.breadCrumbs}>
            <Link
              color="inherit"     
              component={RouterLink}
              to={props.breadcrumbsRoot.path}>
              {props.breadcrumbsRoot.name}
            </Link>
            <Typography className={clsx(classes.breadCrumbs, classes.active)}>
              {currentLink.name}
            </Typography>
          </Breadcrumbs> 
    </Box>       
  );
}
