import React from 'react';
import  {TextField,InputAdornment} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  textField : {
    boxShadow: `1px 1px 3px ${theme.palette.grey2}`,
    minWidth: theme.spacing(34) , 
    [theme.breakpoints.up('md')] : {
      minWidth: theme.spacing(40) , 
    },    
    '& .MuiInputBase-root': {     
       maxHeight: '38px',
       '& .MuiInputBase-input': {
          paddingTop: theme.spacing(1.2),
          paddingBottom: theme.spacing(1.2)
       }
    } 
  }  
}))

/** Search/Filter on top of main table */
export default function TableFilter(props) {
  const classes = useStyles();
  return (   
    <TextField
      margin="dense"
      variant="filled"  
      className = {classes.textField}  
      placeholder = 'Search' 
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon color = 'primary'/>
          </InputAdornment>
        ),
      }}
      onChange={props.handleSearchChange}
    />
  );
}