import React from 'react';
import './styles.css';
import {Hint, Loader} from '../../components/shared';
import {Search} from '../../components/well-search';
import {Trajectory} from '../../components/trajectory';
import {TrajectoryToDraw} from '../../store/trajectory';

const hintTitle = 'No Trajectory to display';
const hintSubTitle = 'Find well and click visualize to appropriate data set';

export interface MainPageProps {
    isTrajectoryLoading: boolean;
    trajectoriesToDraw: TrajectoryToDraw[];
}

/**
 * Contains login-logout functionality, search wells form,
 * found wells list and area for drawing well trajectories
 */
export function MainPage({isTrajectoryLoading, trajectoriesToDraw}: MainPageProps) {
    return (
        <div className="main">
            <div className="main__page">
                {/* wells search with results */}
                <Search/>

                {/* trajectory canvas */}
                <div className="main__chart-area">
                    {trajectoriesToDraw.length !== 0 ? (
                        <Trajectory trajectoriesToDraw={trajectoriesToDraw}/>
                    ) : isTrajectoryLoading ? (
                        /*
                         * I don't want to obscure a drawn trajectory while sibbling one is loading,
                         * therefore this branch is under a double condition
                         */
                        <Loader/>
                    ) : (
                        <Hint title={hintTitle} subTitle={hintSubTitle}/>
                    )}
                </div>
            </div>
        </div>
    );
}
