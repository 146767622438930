// This component is an example for stepper demo

import React from 'react';
import {
    FormControl, TextField, Typography, FormControlLabel, Checkbox,
    Grid, Radio, Switch, RadioGroup, FormGroup, FormHelperText, Button
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';
import {makeStyles} from '@material-ui/core/styles';
import { default as ProgressStepper } from './../Stepper/Stepper';

const useStyles = makeStyles(theme => (
    {           
        formControl : {
            display: 'block',
            padding: 0,
            minWidth: '320px'
        },   
        formTitle: {
            marginBottom: theme.spacing(2),
            paddingTop: theme.spacing(1.5),
            paddingBottom: theme.spacing(1.5),
            borderBottom: `2px solid ${theme.palette.secondary.dark}`
        },
        textField: {
            width: '100%'
        },
        buttonContainer : {
            marginBottom: theme.spacing(2),
            marginTop   : theme.spacing(3)
        }  
    }
))
const StepperForm = (props) => {
   const classes= useStyles();
    const [state, setState] = React.useState({
        checkedA: true,
        checkedB: true,
    });
    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };
    return (
        <form noValidate autoComplete="off">
            <ProgressStepper
                children={
                    [
                        <FormControl className = {classes.formControl}>
                            <Typography className={classes.formTitle}
                                variant='h6' children='Create new workload' />
                               <FormHelperText>Enter a workload name</FormHelperText>
                            <TextField
                                className = {classes.textField}
                                variant='filled'
                                type='text'
                                label='Worload name'
                                margin='dense'
                                color='primary'                                
                            />
                            <Typography className={classes.formTitle}
                                variant='h6' children='Select an environment' />
                            <RadioGroup aria-label="options" name="options"
                                value={props.value} onChange={props.handleChange}>
                                <FormControlLabel value="environmentId1" control={<Radio color="primary" />} label="Core" />
                                <FormControlLabel value="environmentId2" control={<Radio color="primary" />} label="DevTestEnv" />
                                <FormControlLabel value="environmentId3" control={<Radio color="primary" />} label="DevTestEnv" />
                            </RadioGroup>
                        </FormControl>,
                        <FormControl className = {classes.formControl}>
                            <Typography className={classes.formTitle}
                            variant='h6' children='Available in regions' />
                            <FormHelperText>Choose region(s) where the workload will be available</FormHelperText>
                            <Grid container spacing={4} >
                                <Grid item sm={12} lg={6}>
                                    <FormControlLabel
                                        control={<Checkbox name="checkedB" color="primary" checked />}
                                        label="US East (N. Virginia)"
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox name="checkedB" color="primary" />}
                                        label="US Ohio"
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox name="checkedB" color="primary" />}
                                        label="US West (N. California)"
                                        labelPlacement="end"
                                    />
                                </Grid>
                                <Grid item sm={12} lg={6}>
                                    <FormControlLabel
                                        control={<Checkbox name="checkedB" color="primary" />}
                                        label="US West Oregon"
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox name="checkedB" color="primary" />}
                                        label="Europe"
                                        labelPlacement="end"
                                    />
                                </Grid>
                            </Grid>
                            <Typography className={classes.formTitle}
                            variant='h6' children='Enable on creation' />
                            <FormHelperText>Toggle the switch to enable or disable the workload on creation</FormHelperText>
                            <FormGroup row>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={state.checkedB}
                                            onChange={handleChange}
                                            name="checkedB"
                                            color="primary"
                                        />
                                    }
                                    label="Enabled"
                                />
                            </FormGroup>
                        </FormControl>,
                        <FormControl className = {classes.formControl}>
                         <Typography className={classes.formTitle}
                            variant='h6' children='Confirmation' />
                             <Typography variant='body1'>
                                Lorem Ipsum has been the industry's standard dummy text ever since the 
                                1500s when an unknown printer took a galley of type and scrambled it to 
                                make a type specimen book. It has survived not only five centuries, 
                                but also the leap into electronic typesetting, remaining essentially unchanged.
                             </Typography>
                            <Grid container justify='space-between'
                                className = {classes.buttonContainer}>
                                <Grid item container sm={6} justify='flex-start'>
                                    <Button variant='outlined'
                                        color='secondary'
                                        children='Cancel'
                                        onClick={props.handleClick}
                                        startIcon = {<CancelIcon/>}
                                    />
                                </Grid>
                                <Grid item container sm={6} justify='flex-end'>
                                    <Button variant='contained'
                                        color='primary'
                                        children='Create'
                                        onClick={props.handleClick}
                                        startIcon = {<AddIcon/>}
                                    />
                                </Grid>
                            </Grid>
                        </FormControl>
                    ]
                } />

        </form>

    )

}

export default StepperForm;