import Rig from "../../style/assets/Images/rig-2251648.png";
import React from "react";
import Windmill from "../../style/assets/Images/Industry_Energy_Intro.png";
import Cards from "../../components/cards/Cards";
import CodeIcon from "@material-ui/icons/Code";
import Search from "./Search";


const ITEMS = [
    {
        title: "Search",
        description:
            <>
                Web interface that allows search through data that is already loaded into the system.
            </>
        ,
        image: <img src={Windmill} alt='x'/>,
        path: '/Search',
        component: Search
    },
    {
        title: "Load",
        description:
            <>
                Web interface that allows to load new data to the system.
            </>
        ,
        image: <img src={Rig} alt="x"/>,
        path: '/Load'
    }
]

export default [
    {
        name: 'Management Console',
        path: '/OSDUR2ManagementConsole',
        component: () => (<Cards items={ITEMS}/>),
        addToSideMenu: true,
        wrappedWithNavigation: true,
        exact: true,
        icon: <CodeIcon/>
    },
    ...ITEMS.filter(i => i.component).map(i => {
        return {
            path: i.path,
            component: i.component,
            wrappedWithNavigation: true,
            exact: true
        }
    })
]