import { default as pallete } from '../palette'

export default {
    root: {
        padding: "8px 19px",
        textTransform: 'none',
        borderRadius: 0,
        boxShadow: 'none'
    },   
    contained: {       
        color: pallete.primary.main,
        backgroundColor:pallete.white,
        boxShadow:'none',
        '&:hover':{
            backgroundColor: pallete.background.grey2
        }
    },   
    containedPrimary: {
        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
        '&:hover': {
            backgroundColor: pallete.primary.light
        }        
    },
    outlined: {
        borderColor: pallete.white,
        color: pallete.white,  
        padding: "8px 19px",      
        '&:hover': {
            backgroundColor: '#043D6D'
        }      
    },
    outlinedPrimary: {
        padding: "8px 19px",
        '&:hover': {
            backgroundColor: '#CBDFEC'
        }      
    },
    text: {
        padding: "8px 19px",
        color: pallete.white,
        '&:hover': {
            backgroundColor: pallete.primary.dark
        } 
    }
    
};
