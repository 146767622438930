import React from 'react';
import './styles.css';
import {Wellbore as WellboreModel} from '../../../store/well-search';
import {TrajectoryToDraw} from '../../../store/trajectory';
import {Loader} from '../../shared/loader';
import {TooltipPane} from '../../shared/tooltip-pane';
import {Button} from "@material-ui/core";

export interface WellboreProps {
    /** a wellbore model to be represented by the component */
    wellbore: WellboreModel;

    /** trajectories to draw a visualize button in all its forms */
    selectedTrajectories: TrajectoryToDraw[];

    /** a toggle of a trajectory visualization */
    onSelected: (srn: string, selected: boolean) => void;
}

export function Wellbore({wellbore, selectedTrajectories, onSelected}: WellboreProps) {
    const trajectory = selectedTrajectories.find(st => st.wellboreId === wellbore.resourceId);
    const toggleTrajectorySelected = () => {
        onSelected(wellbore.resourceId, trajectory !== undefined);
    };

    return (
        <li className="wellbore">
            <span>{wellbore.facilityName}</span>
            {/* an action-button/satus to the right to interact with trajectory 3D visualization feature */}
            <div className="wellbore__control">{getControl(trajectory, toggleTrajectorySelected)}</div>
        </li>
    );
}

/**
 * The so-called 'visualize-button' in fact has multiple variations
 * and they are groupped is this method not to bloat main component template
 * *maybe it's a sign to move the code to a separate file
 * @param trajectory a selected trajectory model - we need its state descriptors
 * @param toggleTrajectorySelected add/remove from a list to visualize the trajectory
 */
function getControl(
    trajectory: TrajectoryToDraw | undefined,
    toggleTrajectorySelected: () => void
) {
    if (!trajectory) {
        // a visualize button itself
        return (
            <Button variant="contained" size="small" color="primary"
                    onClick={toggleTrajectorySelected}>Visualize</Button>
        );
    }

    if (trajectory.isLoading) {
        return <Loader size={8}/>;
    }

    if (trajectory.loadError !== undefined) {
        // an error "state-button" will be displayed and a description available in a tooltip
        // an attached toggle action will allow to re-run fetch process
        return (
            <Button variant="outlined" size="small" color="secondary" onClick={toggleTrajectorySelected}>
                Error
                <TooltipPane className="wellbore__action-tooltip" doApplyStylesForText={true}>
                    {trajectory.loadError.message}
                </TooltipPane>
            </Button>

        );
    }

    if (trajectory.isLoaded) {
        // a success "state-button", activation will hide trajectory's visualization
        return (
            <Button variant="outlined" size="small" color="primary" onClick={toggleTrajectorySelected}>Hide</Button>
        );
    }
    return null;
}
