import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Scrollbars } from 'react-custom-scrollbars';
import { AppBar, Tabs, Tab, Box, Grid } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.grey2        
    },
    appBar: {
        backgroundColor: theme.palette.primary.dark,
        borderRadius: `4px 4px 0 0`,
        zIndex: 0        
    },
    tableTitle: {
        fontSize: 16,
        fontWeight: 700,
        color: theme.palette.black,
        marginBottom: theme.spacing(2)
    },
    tabHeader: {
        paddingLeft: theme.spacing(3)
    },
    tabTitle: {
        fontSize: '14px',
        lineHeight: '17px',
        letterSpacing: '0.42px',
        fontWeight: 600,
        textTransform: 'none'
    },
    tabContent : {
        background: theme.palette.white
    }
   
}));

/** Single tab panel */
function TabPanel(props) {
    const classes = useStyles();
    return (
        <Box
            className={classes.tabContent}
            component="div"
            role="tabpanel"
            hidden={props.value !== props.index}
            id={`simple-tabpanel-${props.index}`}
            aria-labelledby={`simple-tab-${props.index}`}
        >
            {props.value === props.index && <Box p={3}>{props.children}</Box>}
        </Box>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


/** Tab component, including all tabs and panels */
export default function TabbedDetail(props) {

    const classes = useStyles();
    const [value, setValue] = useState(0);

    /** change current open tab */
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    //Checks to make sure tab component is not placeholder text
    const getTabComponent = (tab) => {
        if (React.isValidElement(tab.component)) {
            /* Cloning the tab conponent in order to pass in props */
            return React.cloneElement(tab.component, { rows: props.rows, columnInfo: props.columnInfo, selectedId: props.selectedId });
        } else {
            return tab.component;
        }
    };

    return (        
        <div className={classes.root} >             
            <Grid container item xs={12}>              
                {Boolean(props.selectedId) ?
                    <>
                        <AppBar position="static" className={classes.appBar}>
                            <Tabs value={value} onChange={handleChange} aria-label="detail tabs">
                                {props.tabsContent.tabsArray.map((tab, index) => {
                                    return (
                                        <Tab
                                            label={tab.label}
                                            className={classes.tabTitle}
                                            {...a11yProps(index)}
                                            key={tab.label}
                                        />
                                    );
                                })}

                            </Tabs>
                        </AppBar>
                        <Scrollbars style={{ width: '100%', height: `${props.contentScrollHeight}px`,background:'#ffffff' }}>  
                            {props.tabsContent.tabsArray.map((tab, index) => {
                                return (
                                
                                        <TabPanel
                                            value={value}
                                            index={index}
                                            key={tab.label}
                                        >
                                        {getTabComponent(tab)}
                                        </TabPanel>
                                );
                                })
                            }
                         </Scrollbars>
                    </>
                    : <Box />
                }
            </Grid>
        </div>       
    );
}
