import React from "react";
import {ReactComponent as ReactLogo} from './47L_PaaS_Logo_White_Horizontal.svg'

function SvgLogoWhite(props) {
  return (
      <ReactLogo/>
  );
}

export default SvgLogoWhite;
