import {createSlice} from '@reduxjs/toolkit';

import terms_of_use from "../assets/terms_of_use.pdf"
import terms_of_use_hl from "../assets/terms_of_use_hl.pdf"

export function getFooterLinks() {
    // ugly way to stop production build optimization
    let r = Math.random().toString(36).substring(7);
    const termsVariant = r + process.env.REACT_APP_TERMS_VARIANT

    let termsOfUse = terms_of_use
    if (termsVariant === r + 'hl') {
        termsOfUse = terms_of_use_hl
    }
    return [
        {text: 'Terms of Use', path: termsOfUse},
        {text: 'Privacy Policy', path: 'https://www.hitachivantara.com/en-us/company/legal.privacy.html'},
        {text: 'Legal', path: 'https://www.hitachivantara.com/en-us/company/legal.html'}
    ]
}

export const sharedSlice = createSlice({
    name: 'shared',
    initialState: {
        footerLinks: getFooterLinks(),
    },
    reducers: {
        setFooterLinks: (state, action) => {
            state.footerLinks = action.payload
        }
    }
});


export const selectFooterLinks = state => {
    return state.shared.footerLinks;
}
export const {setFooterLinks} = sharedSlice.actions;

export default sharedSlice.reducer;