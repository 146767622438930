import Rig from "../../style/assets/Images/rig-2251648.png";
import B from "../../style/assets/Images/b.jpg";
import SolarPanel from "../../style/assets/Images/Home_Intro.png";
import Windmill from "../../style/assets/Images/Industry_Energy_Intro.png";
import A from "../../style/assets/Images/a.jpg";
import React from "react";
import Cards from "../../components/cards/Cards";
import ExploreIcon from "@material-ui/icons/Explore";
import Services from "./Services";
import OSDUTestDataSet from "./OSDUTestDataSet";
import AWSIntegration from "./AWSIntegration";
import Endpoints from "./Endpoints";
import OSDUTutorialsSampleApplication from "./OSDUTutorialsSampleApplication";

const base = process.env.REACT_APP_BASE_API_URL_DISPLAY;

const ITEMS = [
    {
        title: "Services",
        description:
            <>
                47Lining has included all of the standard services provided by the OSDU™ Data Platform's latest release. This
                includes the components that allow the OSDU Data Platform to be deployed.
            </>
        ,
        image: < img src={Rig} alt="x"/>,
        path: '/Services',
        component: Services
    }, {
        title: "Test Data Sets",
        description:
            <>
                Included for use in this environment is the OSDU™ Forum provided schemas and test data sets. The <a
                className='text-link' rel="noopener noreferrer" target="_blank"
                href='https://community.opengroup.org/osdu/platform/open-test-data'>
                test dataset</a> in the OSDU Data Platform includes Wells and Seismic domains.
            </>
        ,
        image: < img src={B} alt="x"/>,
        path: '/OSDUTestDataSet',
        component: OSDUTestDataSet
    },
    {
        title: "Your OSDU™ Service Endpoints",
        description:
            <>
                The following information shows how you can access the OSDU service endpoints for this installation.
                They are all based upon the Preview Deployment URL for the Management Console as your {base}.

                Using the {base} you can build the following service endpoints.
            </>
        ,
        image: < img src={Rig} alt="x"/>,
        path: '/Endpoints',
        component: Endpoints
    },
    {
        title: "Management Console",
        description:
            <>
                47Lining has supplied this User Interface to give the OSDU™ Data Platform user a place to go for
				information and perform some simple tasks. The OSDU Data Platform does not come with a user interface
				and as such this User Interface is specific to the 47Lining Enterprise PaaS - Preview Deployment offering.
            </>
        ,
        image: <img src={Windmill} alt='x'/>,
        path: '/OSDUR2ManagementConsole'
    },
    {
        title: "OSDU™ Tutorials Sample Application",
        description:
            <>
                The OSDU Forum created a tutorial application that demonstrates many of the services acting on the test
                datasets. 47Lining has deployed that tutorial application and connected it to this environment for
                exploration. The web application allows for search and visualization of Wells datasets. It is a good
                sample for how to authenticate to the platform, interact with the apis and make an application out of
                the OSDU Data Platform data.
            </>
        ,
        image: <img src={A} alt="x"/>,
        path: '/OSDUTutorialsSampleApplication',
        component: OSDUTutorialsSampleApplication
    }, {
        title: "AWS Integration",
        description:
            <>
                The 47Lining Enterprise PaaS - Preview Deployment is deployed into a single AWS Account. All AWS services
				used will be owned and billed to that account. The OSDU Forum provides open source code that is then
				deployed to AWS and linked to the Cloud Provider specific services through a Service Provider
				Interface(SPI). In somecases the infrastructure is dedicated and persistent and in other cases it is
				serverless and/or ephemeral.
            </>
        ,
        image: <img src={SolarPanel} alt="x"/>,
        path: '/AWSIntegration',
        component: AWSIntegration
    }
    
]

export default [
    {
        name: 'Explore Current Implementation',
        path: '/ExploreCurrentImplementation',
        component: () => (<Cards items={ITEMS}/>),
        addToSideMenu: true,
        wrappedWithNavigation: true,
        exact: true,
        icon: <ExploreIcon/>
    },
    ...ITEMS.filter(i => i.component).map(i => {
        return {
            path: i.path,
            component: i.component,
            wrappedWithNavigation: true,
            exact: true
        }
    })
]