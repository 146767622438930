import Login from "./Login";
import Reset from "./Reset";
import HOME from "./home";
import EXPLORE_CURRENT_IMPLEMENTATION from "./explore_current_implementation";
import MEET_FUTURE_NEEDS from "./meet_future_needs";
import OSDU_IN_PRODUCTION from "./osdu_in_production";
import OSDU_R2_MANAGEMENT_CONSOLE from "./osdu_r2_managment_console";
import SAMPLE_APP from "./sample-app"

export default [
    ...HOME,
    ...EXPLORE_CURRENT_IMPLEMENTATION,
    ...MEET_FUTURE_NEEDS,
    ...OSDU_IN_PRODUCTION,
    ...OSDU_R2_MANAGEMENT_CONSOLE,
    ...SAMPLE_APP,
    {path: '/login', component: Login, exact: true},
    {path: '/r', component: Reset, exact: true}
]