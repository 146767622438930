import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {LatLng} from 'leaflet';
import {determineCurrentUserPositionAction, setInitialWellAction, Well} from '../../store/map';
import {useLocation} from 'react-router-dom';
import {MapPage} from './MapPage';
import {AppState} from '../../store';

/**
 * Creates a map which includes a map view, control panel with buttons,
 * draw/edit polygon (the area that creates boundaries for finding wells),
 * select found wells on the map and passing these well ids to the draw cross-section page
 */
export function ConnectedMapPage() {
    const dispatch = useDispatch();
    const location = useLocation();
    const selectedWellIds = useSelector((state: AppState) => state.map.selectedWellIds);
    const wells = useSelector((state: AppState) => state.map.wells);
    const selectedWells: Well[] = selectedWellIds.map(id => wells.find(w => w.resourceId === id) as Well);

    // a certain well may be given to the page as a set of parameters in a url
    // lets try to parse it and set as initial data to work with
    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const lat = Number(query.get('lat'));
        const lng = Number(query.get('lng'));
        const wellId = query.get('wellId') || '';
        const wellName = query.get('wellName') || '';

        dispatch(
            setInitialWellAction(
                lat && lng && wellId && wellName
                    ? {
                        facilityName: wellName,
                        location: new LatLng(lat, lng),
                        resourceId: wellId,
                    }
                    : undefined
            )
        );
    }, [location.search, dispatch]);

    // lets popup that lovely "...wants to know your location" request
    // *solely for improving a user experience with this interface, we swear!
    useEffect(() => {
        dispatch(determineCurrentUserPositionAction());
    }, [dispatch]);

    return <MapPage selectedWells={selectedWells}/>;
}
