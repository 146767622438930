import React from 'react';
import './styles.css';
import {Formation} from '../../../store/cross-section';

export interface LegendProps {
    /** all possible formations across current wells */
    formations: Formation[];

    /**
     * a list of colors to mark each formation
     * in case the number of formations exceed the number of passed colors later will be loopped
     */
    colors: string[];
}

export function Legend({formations, colors}: LegendProps) {
    return (
        <ul className="legend">
            {formations.map((formation, ind) => {
                return (
                    <li className="legend__item" key={formation}>
            <span
                className="legend__item-mark"
                style={{background: colors[ind % colors.length]}}
            />
                        {formation}
                    </li>
                );
            })}
        </ul>
    );
}
