import {Card, CardContent} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React from "react";

export default function EnterpriseAppIntegration() {
    return (
        <div>
            <Card>
                <CardContent>
                    <Typography variant="h4">
                        Integrate OSDU™ with Enterprise Applications
                    </Typography>
                    <br/>
                    <Typography component="div">
                        When an organization decides to rollout the OSDU Data Platform, it will be necessary to integrate
						with corporate standard applications and protocols. In many large organizations those enterprise
						applications are dictated and must be used regardless of software running on-premise or in
						the cloud via SaaS.
                    </Typography>
                    <br/>
                    <br/>
                    <Typography variant="h5">
                        Identity Provider
                    </Typography>
                    <br/>
                    <Typography component="div">
                        The OSDU Data Platform will need to integrate with a corporate Identity Provider (IdP) for user
						authentication. This can be very different for different customers, so in order to meet each
						organization’s needs, flexibility is key. The 47Lining implementation of the OSDU standard on AWS
						is capable of supporting AWS Cognito. This will require additional configuration in order to tie
						into each Customer’s corporate Identity Provider. For the evaluating the OSDU Data Platform,
						AWS Cognito is likely to meet the need. An important consideration to keep in mind for the future
						is what the solution will be a production setting of the OSDU Data Platform.
                    </Typography>
                    <br/>
                    <br/>

                    <Typography variant="h5">
                        Corporate Policy Data Security
                    </Typography>
                    <br/>
                    <Typography component="div">
                        The OSDU Data Platform is pre-built with the concept of users, roles and entitlements built into
						the system. In order to define who can see what, organizations will have to design and plan how
                        users are organized into groups and those groups are assigned permissions of what data can be
                        seen. Data entitlements will have to be designed so that they are flexible enough to handle each
                        company’s corporate standards and rules within the OSDU Data Platform. That entitlements structure
						design can be driven via API and structured based on what exists in corporate systems today. When
						data is loaded into the system, it is specified how that data fits into the entitlements structure.
                    </Typography>
                    <br/>
                    <br/>

                    <Typography variant="h5">
                        Data Exchange
                    </Typography>
                    <br/>
                    <Typography component="div">
                        The OSDU Data Platform has capabilities to ingest data from and send data to corporate applications.
						As the OSDU Data Platform is intended to be a source of record for data, data exchange will need
						to be designed and built into any operational deployment of the OSDU Data Platform. Data may be
						exchanged with Master Data Management (MDM) systems, Enterprise Resource Planning (ERP),
						Business Intelligence (BI) or any other type of corporate application that needs to send and/or
						receive data from the OSDU Data Platform.
                        <br/>
                        <br/>
                        The OSDU standard defines specific formats for data to be loaded via JSON schemas. In order to push
                        corporate data to a OSDU Data Platform, each company will have to design data extraction routines
						that transform and format data into the OSDU standard structure prior to loading into an OSDU Data
						Platform. Organizations may decide to use corporate standard ETL tools or they may choose to use
						simple data extraction capabilities from each of the corporate applications.
                        <br/>
                        <br/>
                        The OSDU Data Platform can also extract data in the same formats as loaded and/or into other formats.
						In order to push that that data back into corporate applications, each company will have to design
						data extraction routines that call OSDU Services to extract the right data, format it according to the
                        target system and then load that data into existing corporate applications. Organizations may
                        decide to use corporate standard ETL tools or they may use a combination of OSDU Services and
                        application loader scripts with each corporate application.
                    </Typography>
                    <br/>
                    <br/>
                </CardContent>
            </Card>
        </div>
    )
}