import React, {useState} from 'react';
import {Line, LineChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis,} from 'recharts';
import {Formation} from '../../../store/cross-section';
import {ChartDataItem} from '../../../lib/cross-section';
import {TooltipPane} from '../../shared/tooltip-pane';

export interface ChartProps {
    /** wells' markers data, ready to be loaded into recharts */
    data: ChartDataItem[];

    /** all possible formations across current wells, they are need to configure lines of a chart */
    formations: Formation[];

    /** indexes, related to a data array telling where a wellbore representation should be drawn */
    wellsToDrill: number[];

    /**
     * a list of colors to mark each formation
     * in case the number of formations exceed the number of passed colors later will be loopped
     */
    colors: string[];
}

// FIXME: Got a strange error there:
// three folowed interfaces should be imported from 'recharts'
// they are exists, they can be imported, they are visible to VSCode
// but 'npm start' crushes with an error they cannot be found.
// I've put there minimal subset of props of those interfaces
interface LineProps {
    name: string;
}

interface TooltipProps {
    payload?: TooltipPayload[];
}

interface TooltipPayload {
    name: string;
    value: number;
}

export function Chart({data, formations, wellsToDrill, colors}: ChartProps) {
    const [currentLine, setCurrentLine] = useState<LineProps | undefined>(undefined);

    const handleLineOver = (line: LineProps) => {
        setCurrentLine(line);
    };

    const handleLineLeave = () => {
        setCurrentLine(undefined);
    };

    const renderTooltip = (props: TooltipProps) => {
        if (currentLine === undefined || props.payload === undefined) {
            return null;
        }

        const tooltipData: TooltipPayload | undefined = props.payload.filter(
            p => p.name === currentLine.name
        )[0];
        if (tooltipData === undefined) {
            return null;
        }

        return (
            <TooltipPane doApplyStylesForText={true}>
                <span>{`${tooltipData.name} : ${-tooltipData.value}`}</span>
            </TooltipPane>
        );
    };

    return (

        <ResponsiveContainer height="100%" width="100%">
            <LineChart
                data={data}
                margin={{
                    top: 20,
                    right: 20,
                    bottom: 20,
                    left: 20,
                }}>
                <Tooltip content={renderTooltip} cursor={false} isAnimationActive={false}/>
                <XAxis orientation="top" dataKey="commonName"/>
                <YAxis domain={[0, 'dataMax']}/>

                {/* Each formation should be defined by an Area with a corresponding color */}
                {formations.map((formation, ind) => {
                    const color = colors[ind % colors.length];
                    return (
                        <Line
                            onMouseOver={handleLineOver}
                            onMouseLeave={handleLineLeave}
                            key={ind}
                            type="monotone"
                            dataKey={formation}
                            name={formation}
                            stroke={color}
                            dot={false}
                            strokeWidth={3}
                            label={false}
                        />
                    );
                })}

                {/* Hey! A thick vertical "reference line" looks just like a straight wellbore! */}
                {wellsToDrill.map(w => (
                    <ReferenceLine key={w} x={w} stroke="grey" strokeWidth={10}/>
                ))}
            </LineChart>
        </ResponsiveContainer>) as any;

}
