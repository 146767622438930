import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Popper, Hidden} from '@material-ui/core';
import { BackToMainMenu } from '../..';

const useStyles = makeStyles(theme => ({
    popper: {
        backgroundColor: theme.palette.sideBarMenu,
        borderRadius: '2px',
        zIndex: theme.zIndex.popper,
        height: `calc(100vh - ${theme.height.topBar}px)`,
        width: '100%',       
        boxShadow: `0px 6px 10px rgba(0, 0, 0, 0.14)`,
        overflowY: 'auto',
        left: '-5px',
        [theme.breakpoints.up('lg')]: {
          backgroundColor: theme.palette.white,
          height: 'auto',
          top: `0!important`,
          left: 0 
        }
      },
      popperInner: {
        padding: theme.spacing(2)
      },
}));
const PopperWithStyles = (props) => {
    const {placement = 'bottom-start',handleClose,children,anchorEl, ...rest} = props;
    const classes= useStyles();
    return(
        <Popper open={Boolean(anchorEl)}
          className={classes.popper}
          anchorEl={anchorEl}
          disablePortal          
          {...rest}
          transition placement={placement}>
        <Hidden lgUp>
          <BackToMainMenu handleClick = {handleClose} />
        </Hidden> 
         {children}
      </Popper>   
    )
}

export default PopperWithStyles;

PopperWithStyles.propTypes = {
  anchorEl: PropTypes.object,
  handleClose: PropTypes.func.isRequired, 
  children: PropTypes.node.isRequired,
  placement: PropTypes.string
}