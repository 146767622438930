import React from 'react';
import {HTMLAttributes} from 'react';
import './styles.css';
import {Button, Loader} from '../../../components/shared';
import {TooltipPane} from '../../shared/tooltip-pane';

/**
 * An interface of the props ControlButton React component
 * @param title - button tooltip text
 * @param icon - button icon
 * @param disabled - is button highlighted and is a cursor disabled on mouse hover
 */
export interface ControlButtonProps extends HTMLAttributes<HTMLButtonElement> {
    /** will be shown in a tooltip, next to the button */
    title: string;

    /** an icon to place in the middle of a button */
    icon: JSX.Element;

    disabled?: boolean;

    /**
     * in case of, a Loader icon will be drawn instead of a given one.
     * also active loading treated like a disabled status.
     */
    isLoading?: boolean;
}

/**
 * Button for the control panel
 * @param {ControlButtonProps} props
 */
export function ControlButton({
                                  disabled,
                                  title,
                                  icon,
                                  isLoading,
                                  ...restProps
                              }: ControlButtonProps) {
    // prevent any button activity while loading
    const actAsDisabled = disabled || isLoading;

    return (
        <div className="control-button">
            <Button
                disabled={actAsDisabled}
                className={`constrol-button__button ${
                    actAsDisabled ? 'constrol-button__button--disabled' : ''
                }`}
                {...restProps}>
                {isLoading ? <Loader size={14}/> : icon}
            </Button>
            <TooltipPane className="control-button__hint" doApplyStylesForText={true}>
                {title}
            </TooltipPane>
        </div>
    );
}
